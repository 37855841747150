<template>
    <div>
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            :title="titleItemsActivity(itemSelectedDetails)"
            :show.sync="editActivity"
        >
            <CRow>
                <CCol v-if="itemSelectedDetails.FgName">
                    <CInput
                        :label="$t('label.name_pilot')"
                        v-uppercase
                        v-model="itemSelectedDetails.Name"
                        :disabled="itemSelectedDetails.FgDisabled"
                        required 
                    >
                    </CInput>
                </CCol>
                <CCol sm="12" v-if="itemSelectedDetails.FgObservation">
                    <CTextarea
                        :label="$t('label.observation')"
                        type="textarea"
                        v-uppercase
                        v-model="itemSelectedDetails.Observation"
                        :disabled="itemSelectedDetails.FgDisabled"
                        maxlength="250"
                        :plaintext="false"
                        :readonly="false"
                        :lazy="(true,400)"
                    >
                    </CTextarea>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    size="sm"
                    color="add"
                    class="m-2"
                    @click="updatedData"
                    :disabled="apiStateLoading"
                >
                    <div v-if="apiStateLoading">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    size="sm"
                    color="wipe"
                    @click="cerrarModal"
                    :disabled="apiStateLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
        <CButton
            @click="cerrarTodo"
            color="dark"
            size="sm"
            class="my-2 my-sm-0 ml-2 d-flex align-items-center"
            shape="pill"
            v-if="VisitId !== ''"
        >
            <CIcon name="cil-chevron-circle-left-alt" />
                <span class="mx-2">{{$t('button.exit')}}</span>
        </CButton>
        <form-wizard
            ref="wizard"
            title="" subtitle=""
            @on-loading="setLoading"
            :nextButtonText="$t('buttonWizard.Next')" 
            :backButtonText="$t('buttonWizard.Back')" 
            :finishButtonText="$t('buttonWizard.Finish')"
            @on-complete="onComplete"
            @on-change="handleTabChange"
            color="#00bdd8"
        >
            <tab-content :before-change="registerDataTerminal" :title="$t('label.menu_diary.TERMINAL_DETAILS')" icon="fa fa-ship">
                <div>
                    <CRow>
                        <CCol sm="3">
                            <CSelect
                                :label="$t('label.operationType')"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.OperationId.$error }"
                                addLabelClasses="required"
                                @blur="$v.formTerminalVisity.OperationId.$touch()"
                                @keydown="formTerminalVisity.tecleado = true"
                                :options="optionsListTpOperationAll"
                                :value.sync="formTerminalVisity.OperationId"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.OperationId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.OperationId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <CSelect
                                :label="$t('label.activityType')"
                                :options="optionsListTpConditionPlanningAll"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.ConditionPlanningId.$error }"
                                @blur="$v.formTerminalVisity.ConditionPlanningId.$touch()"
                                @keydown="formTerminalVisity.tecleado = true"
                                addLabelClasses="required"
                                :value.sync="formTerminalVisity.ConditionPlanningId"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.ConditionPlanningId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.ConditionPlanningId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <CSelect
                                :label="$t('label.ship_security_level')"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.ProtectionLevelId.$error }"
                                @blur="$v.formTerminalVisity.ProtectionLevelId.$touch()"
                                addLabelClasses="required"
                                :options="optionsListLevelProtectionAll"
                                :value.sync="formTerminalVisity.ProtectionLevelId"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.ProtectionLevelId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.ProtectionLevelId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <CInput
                                :label="$t('label.ships_captain')"
                                v-uppercase
                                v-model="formTerminalVisity.CaptainName"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.CaptainName.$error }"
                                @blur="$v.formTerminalVisity.CaptainName.$touch()"
                                addLabelClasses="required"
                                required 
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.CaptainName.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.CaptainName.required">{{$t('label.required')}}</div>
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.CaptainName.onlyText">{{$t('label.onlyText')}}</div>
                                </template>
                            </CInput>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="2">
                            <CSelect
                                :label="$t('label.port')"
                                :options="optionsListPortAll"
                                @change="portChange"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.PortId.$error }"
                                @blur="$v.formTerminalVisity.PortId.$touch()"
                                @keydown="formTerminalVisity.tecleado = true"
                                addLabelClasses="required"
                                :value.sync="formTerminalVisity.PortId"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.PortId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.PortId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="2">
                            <CSelect
                                :label="'TERMINAL'"
                                :options="optionsListTerminalAll"
                                :value.sync="formTerminalVisity.TerminalId"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.TerminalId.$error }"
                                addLabelClasses="required"
                                @blur="$v.formTerminalVisity.TerminalId.$touch()"
                                @keydown="formTerminalVisity.tecleado = true"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.TerminalId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.TerminalId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="1">
                            <CInput
                                :label="$t('label.list_terminal.n_toque')"
                                disabled
                                v-uppercase
                                v-model="realToken"
                                required 
                            >
                            </CInput>
                        </CCol>
                        <CCol sm="3">
                            <CSelect
                                :label="$t('label.berth')"
                                :options="optionsListBerthAll"
                                :value.sync="formTerminalVisity.BerthId"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.BerthId.$error }"
                                addLabelClasses="required"
                                @blur="$v.formTerminalVisity.BerthId.$touch()"
                                @change="formTerminalVisity.tecleado = true"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.BerthId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.BerthId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="2">
                            <CInput
                                :label="$t('label.berthLine')"
                                disabled
                                v-uppercase
                                append="MTR"
                                v-model="LineDock"
                                required 
                            >
                            </CInput>
                        </CCol>
                        <CCol sm="2">
                            <CInput
                                :label="$t('label.draft')"
                                disabled
                                v-uppercase
                                append="MTR"
                                v-model="Draft"
                                required 
                            >
                            </CInput>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="3">
                            <CSelect
                                :label="$t('label.portOrigin')"
                                :options="optionsListServicePortAll"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.PortOriginId.$error }"
                                addLabelClasses="required"
                                @blur="$v.formTerminalVisity.PortOriginId.$touch()"
                                @change="formTerminalVisity.tecleado = true"
                                :value.sync="formTerminalVisity.PortOriginId"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.PortOriginId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.PortOriginId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <CSelect
                                :label="$t('label.portDestiny')"
                                :options="optionsListServicePortAll"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.PortDestinationId.$error }"
                                @blur="$v.formTerminalVisity.PortDestinationId.$touch()"
                                @change="formTerminalVisity.tecleado = true"
                                addLabelClasses="required"
                                :value.sync="formTerminalVisity.PortDestinationId"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.PortDestinationId.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.PortDestinationId.required">{{$t('label.required')}}</div>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <label for="">{{$t('label.sspYear')}}</label>
                            <vue-datepicker
                                :lang="$i18n.locale"
                                v-model="formTerminalVisity.SspYear"
                                type="year"
                                value-type="format"
                                :show-second="false"
                                :clearable="false"
                                popup-class=""
                                @keydown="formTerminalVisity.tecleado = true"
                                :disabled-date="validateDateRange"
                            >
                                <template #input>
                                    <CInput
                                        class="w-100 float-left"
                                        v-uppercase
                                        :invalid-feedback="$t('label.required')"
                                        v-model="formTerminalVisity.SspYear"
                                        @keypress="formatHourMMHH($event)"
                                        placeholder="YYYY"
                                        @keydown="formTerminalVisity.tecleado = true"
                                        :disabled-date="validateDateRange"
                                    >
                                        <template #append-content>
                                            <CIcon name="cil-calendar" />
                                        </template>
                                    </CInput>
                                </template>
                                <template #icon-calendar>
                                    <div style="display: none"></div>
                                </template>
                            </vue-datepicker>
                        </CCol>
                        <CCol sm="3">
                            <CInput
                                :label="$t('label.sspNumber')"
                                v-uppercase
                                v-model="formTerminalVisity.SspNumber"
                                :addInputClasses="{ 'is-invalid': $v.formTerminalVisity.SspNumber.$error }"
                                @blur="$v.formTerminalVisity.SspNumber.$touch()"
                                @keypress="SoloNumeros($event)"
                                @keydown="formTerminalVisity.tecleado = true"
                                maxlength="5"
                            >
                                <template #invalid-feedback v-if="$v.formTerminalVisity.SspNumber.$error">
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.SspNumber.minValue">{{$t('label.invalidGreaterThan')+' 0'}}</div>
                                    <div class='text-danger' v-if="!$v.formTerminalVisity.SspNumber.maxLength">{{$t('validation.max')+' 5 '+$t('validation.chars')}}</div>
                                </template>
                            </CInput>
                        </CCol>
                        <CCol sm="3" v-if="bulkCarrier == 2">
                            <CInput
                                :label="$t('label.declaredTons')"
                                @blur="$v.formTerminalVisity.SspPlanedTon.$touch()"
                                @keydown="formTerminalVisity.tecleado = true"
                                placeholder="0"
                                addLabelClasses="text-right"
                                v-model.lazy="formTerminalVisity.SspPlanedTon"
                                v-money="measure"
                                @focus="$v.formTerminalVisity.SspPlanedTon.$touch()"
                                maxlength="12"
                                ref="sspPlanedTonInput"
                            >
                            </CInput>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="6" v-for="(item, index) in JsonActivity" :key="index">
                            <CTabs variant="tabs" :active-tab="0" addTabsWrapperClasses="fixed-tabs-height" addTabsClasses="fixed-tabs-height" addTabClasses="fixed-tab-height" class="fixed-tabs-height">
                                <CTab :title="titleItemsActivity(item)">
                                    <CCard bodyWrapper class="fixed-tabs-height">
                                        <CRow>
                                            <CCol>
                                                <label :class="validarRequerido(item)" for="">{{$t('label.FECHA')}}</label>
                                                <vue-datepicker
                                                    :lang="$i18n.locale"
                                                    v-model="item.Date"
                                                    type="date"
                                                    format="DD/MM/YYYY"
                                                    value-type="format"
                                                    placeholder="YYYY-MM-DD"
                                                    time-title-format="DD/MM/YYYY"
                                                    :disabled-date="(date) => rangeDays(date,item)"
                                                    :show-second="false"
                                                    :clearable="false"
                                                    @input="item.bandera = true"
                                                    :disabled="item.FgDisabled"
                                                    popup-class=""
                                                >
                                                    <template #input>
                                                        <CInput
                                                            class="w-100 float-left"
                                                            v-uppercase
                                                            placeholder="DD/MM/YYYY"
                                                            :invalid-feedback="$t('label.required')"
                                                            v-model.trim="item.Date"
                                                            :disabled="item.FgDisabled"
                                                            @input="item.bandera = true"
                                                            @keypress="formatHourMMHH($event)"
                                                        >
                                                            <template #append-content>
                                                                <CIcon name="cil-calendar" />
                                                            </template>
                                                        </CInput>
                                                    </template>
                                                    <template #icon-calendar>
                                                        <div style="display: none"></div>
                                                    </template>
                                                </vue-datepicker>
                                            </CCol>
                                            <CCol>
                                                <label :class="validarRequerido(item)" for="">{{$t('label.hour')}}</label>
                                                <vue-datepicker 
                                                    :lang="$i18n.locale"
                                                    v-model.trim="item.Hour"
                                                    :ref="item.VisitActivityId+index+'time'"
                                                    :name="item.VisitActivityId+index+'time'"
                                                    type="time"
                                                    format="HH:mm"
                                                    value-type="format"
                                                    placeholder="HH:mm"
                                                    :disabled="item.FgDisabled"
                                                    time-title-format="HH:mm"
                                                    :show-second="false"
                                                    :clearable="false"
                                                    @input="item.bandera = true"
                                                    popup-class=""
                                                >
                                                    <template #input>
                                                        <CInput
                                                            class="w-100 float-left"
                                                            :name="item.VisitActivityId+index+'time'"
                                                            v-uppercase
                                                            placeholder="HH:MM"
                                                            :invalid-feedback="$t('label.required')"
                                                            v-model.trim="item.Hour"
                                                            @input="item.bandera = true"
                                                            :disabled="item.FgDisabled"
                                                            @keypress="formatHourMMHH($event)"
                                                        >
                                                            <template #append-content>
                                                                <CIcon name="cil-clock" />
                                                            </template>
                                                        </CInput>
                                                    </template>
                                                    <template #icon-calendar>
                                                        <div style="display: none"></div>
                                                    </template>
                                                </vue-datepicker>
                                            </CCol>
                                            <CCol v-if="item.FgQuantity">
                                                <CInput
                                                    :label="$t('label.quantity')"
                                                    v-uppercase
                                                    v-model="item.Quantity"
                                                    :disabled="item.FgDisabled"
                                                    :addLabelClasses="validarRequerido(item)"
                                                    type="number"
                                                    min="0"
                                                    max="99"
                                                    onKeyUp="if(this.value>99){this.value='99';}else if(this.value<0){this.value='0';}"
                                                    @input="item.bandera = true"
                                                    required 
                                                >
                                                </CInput>
                                            </CCol>
                                            <CCol v-if="item.FgName">
                                                <CInput
                                                    :label="$t('label.name_pilot')"
                                                    v-uppercase
                                                    v-model="item.Name"
                                                    :disabled="item.FgDisabled"
                                                    @keydown="item.bandera = true"
                                                    :addLabelClasses="validarRequerido(item)"
                                                    required 
                                                >
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12" v-if="item.FgObservation">
                                                <CTextarea
                                                    :label="$t('label.observation')"
                                                    type="textarea"
                                                    v-uppercase
                                                    v-model="item.Observation"
                                                    :disabled="item.FgDisabled"
                                                    @keydown="item.bandera = true"
                                                    :addLabelClasses="validarRequerido(item)"
                                                    :plaintext="false"
                                                    :readonly="false"
                                                    :lazy="(true,400)"
                                                    maxlength="250"
                                                >
                                                </CTextarea>
                                            </CCol>
                                            <CCol sm="12"  v-if="item.FgInspection">
                                                <div role="group" class="form-group form-row">
                                                    <label
                                                        class="
                                                            text-right
                                                            col-form-label col-sm-12 col-lg-6 col-form-label-sm
                                                        "
                                                    >
                                                        {{ $t('label.inspectionLocation') }}
                                                    </label>
                                                    <div class="col-sm-12 col-lg-6 input-group-sm">
                                                        <CInputRadioGroup
                                                            class="mt-1"
                                                            size="sm"
                                                            :options="BerthBayOptions"
                                                            :checked="checkFgBerth(item)"
                                                            @update:checked="setFgBerth($event, item)"
                                                            inline
                                                        />
                                                    </div>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CTab>
                            </CTabs>
                        </CCol>
                    </CRow>
                </div>
            </tab-content>
            <tab-content :before-change="registerDataScheduleArrival" :title="$t('label.menu_diary.AGENDA_DETAILS')+'('+$t('label.berthVisit')+')'" icon="fa fa-list">
                <CRow>
                    <CCol sm="12">
                        <CTabs variant="tabs" :active-tab="0">
                            <CTab :title="$t('label.list_terminal.n_toque')+': '+realToken">
                                <CCard bodyWrapper>
                                    <CRow>
                                        <CCol col="12" xl="12">
                                            <CRow>
                                                <CCol col="2">
                                                    <div class="cajita-texto">
                                                        <h5>{{$t('label.berthVisit')}}</h5>
                                                    </div>
                                                </CCol>
                                                <CCol col="12">
                                                    <CRow>
                                                        <CCol col="4">
                                                            <CInput
                                                                :label="$t('label.voyage')"
                                                                disabled
                                                                v-uppercase
                                                                required
                                                                v-model="voyageName"
                                                                :horizontal="{ label: 'col-sm-6', input: 'col-sm-6'}"
                                                            >
                                                            </CInput>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol col="6" v-for="(item, index) in JsonVisitActivityShedule.ArriveJson" :key="index">
                                                            <div class="form-group form-row fixed-tabs-height" rol="group">
                                                                <label :class="validarRequerido(item)+'col-form-label col-4'" for="pais">
                                                                    {{titleItemsActivity(item)}}
                                                                </label>
                                                                <div class="col-7 input-group-sm">
                                                                    <div class="row">
                                                                        <div class="col-sm-7">
                                                                            <vue-datepicker
                                                                                :lang="$i18n.locale"
                                                                                v-model="item.Date"
                                                                                type="date"
                                                                                format="DD/MM/YYYY"
                                                                                value-type="format"
                                                                                placeholder="YYYY-MM-DD"
                                                                                time-title-format="DD/MM/YYYY"
                                                                                :disabled-date="(date) => rangeDays(date,item)"
                                                                                :disabled="item.FgDisabled"
                                                                                @change="item.bandera = true"
                                                                                :show-second="false"
                                                                                :clearable="false"
                                                                                popup-class=""
                                                                            >
                                                                                <template #input>
                                                                                    <CInput
                                                                                        class="w-100 float-left"
                                                                                        :disabled="item.FgDisabled"
                                                                                        v-uppercase
                                                                                        placeholder="DD/MM/YYYY"
                                                                                        :invalid-feedback="$t('label.required')"
                                                                                        v-model.trim="item.Date"
                                                                                        @keypress="formatHourMMHH($event)"
                                                                                    >
                                                                                        <template #append-content>
                                                                                            <CIcon name="cil-calendar" />
                                                                                        </template>
                                                                                    </CInput>
                                                                                </template>
                                                                                <template #icon-calendar>
                                                                                    <div style="display: none"></div>
                                                                                </template>
                                                                            </vue-datepicker>
                                                                        </div>
                                                                        <div class="col-sm-5" style="padding-left:0px;">
                                                                            <vue-datepicker 
                                                                                :lang="$i18n.locale"
                                                                                v-model.trim="item.Hour"
                                                                                :ref="item.VisitActivityId+index+'time'"
                                                                                :name="item.VisitActivityId+index+'time'"
                                                                                type="time"
                                                                                format="HH:mm"
                                                                                value-type="format"
                                                                                placeholder="HH:mm"
                                                                                time-title-format="HH:mm"
                                                                                :disabled="item.FgDisabled"
                                                                                @change="item.bandera = true"
                                                                                :show-second="false"
                                                                                :clearable="false"
                                                                                popup-class=""
                                                                            >
                                                                                <template #input>
                                                                                    <CInput
                                                                                        class="w-100 float-left"
                                                                                        :disabled="item.FgDisabled"
                                                                                        :name="item.VisitActivityId+index+'time'"
                                                                                        v-uppercase
                                                                                        placeholder="HH:MM"
                                                                                        :invalid-feedback="$t('label.required')"
                                                                                        v-model.trim="item.Hour"
                                                                                        @keypress="formatHourMMHH($event)"
                                                                                    >
                                                                                        <template #append-content>
                                                                                            <CIcon name="cil-clock" />
                                                                                        </template>
                                                                                    </CInput>
                                                                                </template>
                                                                                <template #icon-calendar>
                                                                                    <div style="display: none"></div>
                                                                                </template>
                                                                            </vue-datepicker>
                                                                        </div>
                                                                        <CCol sm="12"  v-if="item.FgInspection">
                                                                            <div role="group" class="form-group form-row">
                                                                                <label
                                                                                    class="
                                                                                        text-right
                                                                                        col-form-label col-sm-12 col-lg-6 col-form-label-sm
                                                                                    "
                                                                                >
                                                                                    {{ $t('label.inspectionLocation') }}
                                                                                </label>
                                                                                <div class="col-sm-12 col-lg-6 input-group-sm">
                                                                                    <CInputRadioGroup
                                                                                        class="mt-1"
                                                                                        size="sm"
                                                                                        :options="BerthBayOptions"
                                                                                        :checked="checkFgBerth(item)"
                                                                                        @update:checked="setFgBerth($event, item)"
                                                                                        inline
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </CCol>
                                                                    </div>
                                                                </div>
                                                                <div class="col-1">
                                                                    <CButton
                                                                        color="dark"
                                                                        v-c-tooltip="{
                                                                            content: $t('label.edit')+' '+$t('label.activity'),
                                                                            placement: 'top-end'
                                                                        }"
                                                                        @click="openModalUpdated(item,1)"
                                                                    >
                                                                        <CIcon name="cil-comment-bubble"/>
                                                                    </CButton>
                                                                </div>
                                                            </div>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCard>
                            </CTab>
                        </CTabs>
                    </CCol>
                </CRow>
            </tab-content>
            <tab-content :before-change="registerDataVoyage" :title="$t('label.voyage')" icon="fa fa-location-arrow">
                <CRow>
                    <CCol sm="5">
                        <CTabs variant="tabs" :active-tab="0">
                            <CTab :title="$t('label.generalInfo')">
                                <CCard bodyWrapper>
                                    <div>
                                        <CRow>
                                            <CCol sm="12">
                                                <CInput
                                                    horizontal
                                                    :label="$t('label.service')"
                                                    v-model="formTripVisity.ServiceCode"
                                                    disabled
                                                    v-uppercase
                                                    required 
                                                >
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CInput
                                                    horizontal
                                                    :label="$t('label.vessel')"
                                                    v-model="vesselName"
                                                    disabled
                                                    v-uppercase
                                                    required 
                                                >
                                                    <template #prepend-content>
                                                        <img style="width: 1rem;height: 1rem;" :src="vesselRouter" alt="vesselrouter" class="img-fluid">
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CSelect
                                                    horizontal
                                                    :label="$t('label.shippingAgent')"
                                                    :addInputClasses="{ 'is-invalid': $v.formTripVisity.shippingAgentId.$error }"
                                                    @blur="$v.formTripVisity.shippingAgentId.$touch()"
                                                    @change="formTripVisity.tecleado = true"
                                                    :options="optionsListClienteAll(1)"
                                                    addLabelClasses="required"
                                                    :value.sync="formTripVisity.shippingAgentId"
                                                >
                                                    <template #invalid-feedback v-if="$v.formTripVisity.shippingAgentId.$error">
                                                        <div class='text-danger' v-if="!$v.formTripVisity.shippingAgentId.required">{{$t('label.required')}}</div>
                                                    </template>
                                                </CSelect>
                                            </CCol>
                                            <CCol sm="12">
                                                <CSelect
                                                    horizontal
                                                    :label="$t('label.portOperator')"
                                                    :addInputClasses="{ 'is-invalid': $v.formTripVisity.portOperatorId.$error }"
                                                    @blur="$v.formTripVisity.portOperatorId.$touch()"
                                                    @change="formTripVisity.tecleado = true"
                                                    :options="optionsListClienteAll(2)"
                                                    addLabelClasses="required"
                                                    :value.sync="formTripVisity.portOperatorId"
                                                >
                                                    <template #invalid-feedback v-if="$v.formTripVisity.portOperatorId.$error">
                                                        <div class='text-danger' v-if="!$v.formTripVisity.portOperatorId.required">{{$t('label.required')}}</div>
                                                    </template>
                                                </CSelect>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </CCard>
                            </CTab>
                        </CTabs>
                    </CCol>
                    <CCol sm="7">
                        <CTabs variant="tabs" :active-tab="0">
                            <CTab :title="$t('label.recordInfo')">
                                <CCard bodyWrapper>
                                    <CRow>
                                        <CCol col="12" xl="12">
                                            <dataTableExtended
                                                class="align-center-row-datatable"
                                                hover
                                                sorter
                                                small
                                                column-filter
                                                :loading="apiStateLoading"
                                                :table-filter="tableTextHelpers.tableFilterText"
                                                :items-per-page-select="tableTextHelpers.itemsPerPageText"
                                                :items="formatedItemsHistory"
                                                :fields="fields"
                                                :noItemsView="tableTextHelpers.noItemsViewText"
                                                :items-per-page="5"
                                                pagination
                                            ></dataTableExtended>
                                        </CCol>
                                    </CRow>
                                </CCard>
                            </CTab>
                        </CTabs>
                    </CCol>
                    <CCol sm="12">
                        <CTabs variant="tabs" :active-tab="0">
                            <CTab title="ATA / ETD">
                                <CCard bodyWrapper>
                                    <CRow>
                                        <CCol sm="12">
                                            <CRow>
                                                <CCol sm="6" v-for="(item, index) in JsonVisitActivityVoyage" :key="index">
                                                    <CTabs variant="tabs" :active-tab="0">
                                                        <CTab :title="titleItemsActivity(item)">
                                                            <CRow>
                                                                <CCol sm="6">
                                                                    <div class="form-row" rol="group">
                                                                        <label  :class="validarRequerido(item)+'col-form-label col-12'" for="pais">
                                                                            {{$t('label.FECHA')}}
                                                                        </label>
                                                                        <div class="col-12 input-group-sm">
                                                                            <vue-datepicker
                                                                                :lang="$i18n.locale"
                                                                                v-model="item.Date"
                                                                                type="date"
                                                                                format="DD/MM/YYYY"
                                                                                value-type="format"
                                                                                placeholder="YYYY-MM-DD"
                                                                                time-title-format="DD/MM/YYYY"
                                                                                :disabled-date="(date) => rangeDays(date,item)"
                                                                                :show-second="false"
                                                                                :clearable="false"
                                                                                :disabled="item.FgDisabled"
                                                                                @change="item.bandera = true"
                                                                                popup-class=""
                                                                            >
                                                                                <template #input>
                                                                                    <CInput
                                                                                        class="w-100 float-left"
                                                                                        v-uppercase
                                                                                        placeholder="DD/MM/YYYY"
                                                                                        :invalid-feedback="$t('label.required')"
                                                                                        v-model.trim="item.Date"
                                                                                        :disabled="item.FgDisabled"
                                                                                        @input="item.bandera = true"
                                                                                        @keypress="formatHourMMHH($event)"
                                                                                    >
                                                                                        <template #append-content>
                                                                                            <CIcon name="cil-calendar" />
                                                                                        </template>
                                                                                    </CInput>
                                                                                </template>
                                                                                <template #icon-calendar>
                                                                                    <div style="display: none"></div>
                                                                                </template>
                                                                            </vue-datepicker>
                                                                        </div>
                                                                    </div>
                                                                </CCol>
                                                                <CCol sm="6">
                                                                    <div class="form-row" rol="group">
                                                                        <label :class="validarRequerido(item)+'col-form-label col-12'" for="pais">
                                                                            {{$t('label.hour')}}
                                                                        </label>
                                                                        <div class="col-12 input-group-sm">
                                                                            <vue-datepicker 
                                                                                :lang="$i18n.locale"
                                                                                v-model.trim="item.Hour"
                                                                                :ref="item.VisitActivityId+index+'time'"
                                                                                :name="item.VisitActivityId+index+'time'"
                                                                                type="time"
                                                                                format="HH:mm"
                                                                                value-type="format"
                                                                                placeholder="HH:mm"
                                                                                time-title-format="HH:mm"
                                                                                :show-second="false"
                                                                                :clearable="false"
                                                                                @change="item.bandera = true"
                                                                                :disabled="item.FgDisabled"
                                                                                popup-class=""
                                                                            >
                                                                                <template #input>
                                                                                    <CInput
                                                                                        class="w-100 float-left"
                                                                                        :name="item.VisitActivityId+index+'time'"
                                                                                        v-uppercase
                                                                                        placeholder="HH:MM"
                                                                                        :invalid-feedback="$t('label.required')"
                                                                                        :disabled="item.FgDisabled"
                                                                                        v-model.trim="item.Hour"
                                                                                        @input="item.bandera = true"
                                                                                        @keypress="formatHourMMHH($event)"
                                                                                    >
                                                                                        <template #append-content>
                                                                                            <CIcon name="cil-clock" />
                                                                                        </template>
                                                                                    </CInput>
                                                                                </template>
                                                                                <template #icon-calendar>
                                                                                    <div style="display: none"></div>
                                                                                </template>
                                                                            </vue-datepicker>
                                                                        </div>
                                                                    </div>
                                                                </CCol>
                                                            </CRow>
                                                        </CTab>
                                                    </CTabs>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol sm="6">
                                            <CInput
                                                :label="$t('label.VOYAGE_ARRIVAL')"
                                                v-uppercase
                                                v-model="formTripVisity.voyageArrival"
                                                :addInputClasses="{ 'is-invalid': $v.formTripVisity.voyageArrival.$error }"
                                                @blur="$v.formTripVisity.voyageArrival.$touch()"
                                                @keydown="formTripVisity.tecleado = true"
                                                required 
                                            >
                                                <template #invalid-feedback v-if="$v.formTripVisity.voyageArrival.$error">
                                                    <div class='text-danger' v-if="!$v.formTripVisity.voyageArrival.required">{{$t('label.required')}}</div>
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol sm="6">
                                            <CInput
                                                :label="$t('label.VOYAGE_DEPARTURE')"
                                                v-uppercase
                                                v-model="formTripVisity.voyageDeparture"
                                                :addInputClasses="{ 'is-invalid': $v.formTripVisity.voyageDeparture.$error }"
                                                @blur="$v.formTripVisity.voyageDeparture.$touch()"
                                                @keydown="formTripVisity.tecleado = true"
                                                required 
                                            >
                                                <template #invalid-feedback v-if="$v.formTripVisity.voyageDeparture.$error">
                                                    <div class='text-danger' v-if="!$v.formTripVisity.voyageDeparture.required">{{$t('label.required')}}</div>
                                                </template>
                                            </CInput>
                                        </CCol>
                                    </CRow>
                                </CCard>
                            </CTab>
                            <CTab  :title="$t('label.shippingLineVoyage')" v-show="bulkCarrier == 1">
                                <CCard bodyWrapper>
                                    <div style="height: 300px">
                                        <div style="height: 100%; box-sizing: border-box;">
                                            <ag-grid-vue
                                            v-if="showGrid"
                                            style="width: 100%; height: 100%;"
                                            class="ag-theme-alpine"
                                            id="myGrid"
                                            :localeText="localeText"
                                            :columnDefs="columnDefs"
                                            @grid-ready="onGridReady"
                                            @rowDoubleClicked="formTripVisity.tecleado = true"
                                            :rowData="ListVisitLine"
                                            :defaultColDef="defaultColDef"></ag-grid-vue>
                                        </div>
                                    </div>
                                </CCard>
                            </CTab>
                        </CTabs>
                    </CCol>
                </CRow>
            </tab-content>
            <tab-content :before-change="registerDataScheduleDeparture" :title="$t('label.menu_diary.AGENDA_DETAILS')+'('+$t('label.departure')+')'" icon="fa fa-list">
                <CRow>
                    <CCol sm="12">
                        <CTabs variant="tabs" :active-tab="0">
                            <CTab :title="$t('label.list_terminal.n_toque')+': '+realToken">
                                <CCard bodyWrapper>
                                    <CRow>
                                        <CCol col="12" xl="12">
                                            <CRow>
                                                <CCol col="2">
                                                    <div class="cajita-texto">
                                                        <h5>{{$t('label.departure')}}</h5>
                                                    </div>
                                                </CCol>
                                                <CCol col="12">
                                                    <CRow>
                                                        <CCol col="4">
                                                            <CInput
                                                                :label="$t('label.voyage')"
                                                                disabled
                                                                v-uppercase
                                                                required 
                                                                v-model="voyageName"
                                                                :horizontal="{ label: 'col-sm-6', input: 'col-sm-6'}"
                                                            >
                                                            </CInput>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol col="6" v-for="(item, index) in JsonVisitActivityShedule.DepartureJson" :key="index">
                                                            <div class="form-group form-row fixed-tabs-height" rol="group">
                                                                <label :class="validarRequerido(item)+'col-form-label col-4'" for="pais">
                                                                    {{titleItemsActivity(item)}}
                                                                </label>
                                                                <div class="col-7 input-group-sm">
                                                                    <div class="row">
                                                                        <div class="col-sm-7">
                                                                            <vue-datepicker
                                                                                :lang="$i18n.locale"
                                                                                v-model="item.Date"
                                                                                type="date"
                                                                                format="DD/MM/YYYY"
                                                                                value-type="format"
                                                                                placeholder="YYYY-MM-DD"
                                                                                time-title-format="DD/MM/YYYY"
                                                                                :disabled-date="(date) => rangeDays(date,item)"
                                                                                :disabled="item.FgDisabled"
                                                                                :show-second="false"
                                                                                :clearable="false"
                                                                                @change="item.bandera = true"
                                                                                popup-class=""
                                                                            >
                                                                                <template #input>
                                                                                    <CInput
                                                                                        class="w-100 float-left"
                                                                                        v-uppercase
                                                                                        placeholder="DD/MM/YYYY"
                                                                                        :invalid-feedback="$t('label.required')"
                                                                                        :disabled="item.FgDisabled"
                                                                                        v-model.trim="item.Date"
                                                                                        @input="item.bandera = true"
                                                                                        @keypress="formatHourMMHH($event)"
                                                                                    >
                                                                                        <template #append-content>
                                                                                            <CIcon name="cil-calendar" />
                                                                                        </template>
                                                                                    </CInput>
                                                                                </template>
                                                                                <template #icon-calendar>
                                                                                    <div style="display: none"></div>
                                                                                </template>
                                                                            </vue-datepicker>
                                                                        </div>
                                                                        <div class="col-sm-5" style="padding-left:0px;">
                                                                            <vue-datepicker 
                                                                                :lang="$i18n.locale"
                                                                                v-model.trim="item.Hour"
                                                                                :ref="item.VisitActivityId+index+'time'"
                                                                                :name="item.VisitActivityId+index+'time'"
                                                                                type="time"
                                                                                format="HH:mm"
                                                                                value-type="format"
                                                                                placeholder="HH:mm"
                                                                                time-title-format="HH:mm"
                                                                                :disabled="item.FgDisabled"
                                                                                :show-second="false"
                                                                                :clearable="false"
                                                                                @change="item.bandera = true"
                                                                                popup-class=""
                                                                            >
                                                                                <template #input>
                                                                                    <CInput
                                                                                        class="w-100 float-left"
                                                                                        :name="item.VisitActivityId+index+'time'"
                                                                                        v-uppercase
                                                                                        placeholder="HH:MM"
                                                                                        :invalid-feedback="$t('label.required')"
                                                                                        :disabled="item.FgDisabled"
                                                                                        v-model.trim="item.Hour"
                                                                                        @input="item.bandera = true"
                                                                                        @keypress="formatHourMMHH($event)"
                                                                                    >
                                                                                        <template #append-content>
                                                                                            <CIcon name="cil-clock" />
                                                                                        </template>
                                                                                    </CInput>
                                                                                </template>
                                                                                <template #icon-calendar>
                                                                                    <div style="display: none"></div>
                                                                                </template>
                                                                            </vue-datepicker>
                                                                        </div>
                                                                        <CCol sm="12"  v-if="item.FgInspection">
                                                                            <div role="group" class="form-group form-row">
                                                                                <label
                                                                                    class="
                                                                                        text-right
                                                                                        col-form-label col-sm-12 col-lg-6 col-form-label-sm
                                                                                    "
                                                                                >
                                                                                    {{ $t('label.inspectionLocation') }}
                                                                                </label>
                                                                                <div class="col-sm-12 col-lg-6 input-group-sm">
                                                                                    <CInputRadioGroup
                                                                                        class="mt-1"
                                                                                        size="sm"
                                                                                        :options="BerthBayOptions"
                                                                                        :checked="checkFgBerth(item)"
                                                                                        @update:checked="setFgBerth($event, item)"
                                                                                        inline
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </CCol>
                                                                    </div>
                                                                </div>
                                                                <div class="col-1">
                                                                    <CButton
                                                                        color="dark"
                                                                        v-c-tooltip="{
                                                                            content: $t('label.edit')+' '+$t('label.activity'),
                                                                            placement: 'top-end'
                                                                        }"
                                                                        @click="openModalUpdated(item,2)"
                                                                    >
                                                                        <CIcon name="cil-comment-bubble"/>
                                                                    </CButton>
                                                                </div>
                                                            </div>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCard>
                            </CTab>
                        </CTabs>
                    </CCol>
                </CRow>
            </tab-content>
        </form-wizard>
        
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import FormVisity  from '@/_validations/visitas/register-terminal';
    import DataStorage from '@/_helpers/localStorage';
    import moment from 'moment';
    import UpperCase from '@/_validations/uppercase-directive';
    import { VMoney, Money } from "v-money";
    import AgGrid from '@/_mixins/ag-grid';
    
    //data
    function data () {
        return {
            loadingOverlay: false,
            activitiesLocations: [],
            time1:null,
            CompanyBranchId:0,
            config: {
                locale: this.$i18n.locale,
                defaultView: 'month',
                //events: this.events,
            },
            loadingWizard:false,
            JsonActivity:[],
            ListTpOperation:[],
            ListTpConditionPlanning:[],
            ListLevelProtection:[],
            ListPort:[],
            ListTerminal:[],
            ListBerth:[],
            ListServicePort:[],
            formTerminalVisity:{
                CaptainName:'',
                ConditionPlanningId:null,
                ProtectionLevelId:null,
                OperationId:null,
                PortId:null,
                PortOriginId:null,
                PortDestinationId:null,
                BerthId:null,
                TerminalId:null,
                tecleado:false,
                SspYear: '',
                SspNumber: null,
                SspPlanedTon: 0,
            },
            measure: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
            },
            Ata:null,
            Atd:null,
            StatusMaster:[],
            ListActivity:[],
            StatusFormulario:0,
            //tab2
            formTripVisity:{
                shippingAgentId: null,
                portOperatorId:null,
                ServiceCode:"",
                ShippingLineAgencyName:"",
                voyageArrival:"",
                voyageDeparture:"",
                tecleado:false,
            },
            gridApi: null,
            columnApi: null,
            defaultColDef: {
                editable: true,
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
            },
            ListAgenteNaviero:[],
            ListOperadorPortuario:[],
            ListServiceItineraryHist:[],
            ListVisitLine:[],
            JsonVisitActivityVoyage:[],
            //tab3
            typeActivity:1,
            editActivity:false,
            JsonVisitActivityShedule:{
                ArriveJson:[],
                DepartureJson:[]
            },
            itemSelectedDetails:{
                Name:"",
                Observation:"",
                Label: {En: "", Es: ""},
                FgName: false,
                FgObservation: false,
                FgDisabled:false
            },
            SheduleArrivalActivityJson:[],
            SheduleDepartureActivityJson:[],
            previousDate: new Date(2000,1),
            laterDate: new Date(),
            berth: '0',
        }
    }
    function rangeDays(date,item) {
        
        let todayMin = DateFormater.formatDateRange(item.MinDate);
        let todayMax = DateFormater.formatDateRange(item.MaxDate);

        return !(date >= todayMin && date <= todayMax);
    }
    function titleItemsActivity(item){
        let local = DataStorage.getLocale();
        return local == 'es'? item.Label.Es : item.Label.En;
    }
    async function resetsInput(){
        this.formTerminalVisity.CaptainName='',
        this.formTerminalVisity.ConditionPlanningId=null,
        this.formTerminalVisity.ProtectionLevelId=null;
        this.formTerminalVisity.OperationId=null;
        this.formTerminalVisity.PortId=null;
        this.formTerminalVisity.PortOriginId=null;
        this.formTerminalVisity.PortDestinationId=null;
        this.formTerminalVisity.BerthId=null;
        this.formTerminalVisity.TerminalId=null;
        this.formTerminalVisity.tecleado = false;
        this.formTerminalVisity.SspNumber=null;
        this.formTerminalVisity.SspYear='';
        this.formTerminalVisity.SspPlanedTon = 0;
        if(this.bulkCarrier == 2){
            let inputSspPlanedTon = this.$refs.sspPlanedTonInput.$el.querySelector("input");
            inputSspPlanedTon.value = "";
        }

        this.formTripVisity.shippingAgentId= null;
        this.formTripVisity.portOperatorId=null;
        this.formTripVisity.ServiceCode="";
        this.formTripVisity.ShippingLineAgencyName="";
        this.formTripVisity.voyageArrival="";
        this.formTripVisity.voyageDeparture="";
        this.formTripVisity.tecleado = false;
        this.StatusFormulario = 0;
        this.berth = '0';
        this.activitiesLocations = [];
        this.$refs.wizard.reset();
        this.$v.$reset();
    }
    async function mountedMont(){
        this.config = await localStorage.getItem('user');
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.config = JSON.parse(this.config);
        //if (typeof(this.ServiceId)=="undefined") this.ServiceId = "";
        if (Object.keys(this.config).length !== 0) {
            this.CompanyBranchId = this.config.BranchJson[0].CompanyBranchId;
        }
        this.$http.ejecutar('GET', 'VisitServicePortList', { ServiceId: this.itinerarySelected.ServiceId,CompanyBranchId: this.CompanyBranchId }, '').then(responseServicePort => {
            if(responseServicePort.status === 200){
                this.$http.ejecutar('GET', 'VisitTerminal-by-CompanyBranchId', { CompanyBranchId: this.CompanyBranchId }, '').then(responseCompanyBranch => {
                    if(responseCompanyBranch.status === 200){
                        this.$http.ejecutar('GET', 'TpOperation-list', {  }, '').then(responseTpOperation => {
                            if(responseTpOperation.status === 200){
                                this.$http.ejecutar('GET', 'TpConditionPlanning-list', {  }, '').then(responseTpConditionPlanning => {
                                    if(responseTpConditionPlanning.status === 200){
                                        let data = responseCompanyBranch.data.data;
                                        
                                        if(data.length !== 0){
                                            let terminalJson = data[0].VisitJson.TerminalJson[0];
                                            this.ListLevelProtection = terminalJson.LevelProtectionJson;
                                            this.ListPort = terminalJson.PortJson;
                                            this.ListTerminal = terminalJson.TerminalJson;
                                            
                                        }

                                        this.ListServicePort = responseServicePort.data.data;
                                        this.ListTpOperation = responseTpOperation.data.data;
                                        this.ListTpConditionPlanning = responseTpConditionPlanning.data.data;
                                        
                                        this.$http.ejecutar('GET', 'VisitTerminalActivicty-list', { ItineraryId:this.ItineraryId }, '').then(responseTerminalList => {
                                            if(responseTerminalList.status === 200){
                                                let JsonActivity = responseTerminalList.data.data[0].JsonActivity;
                                                JsonActivity.sort(function (a, b) {
                                                    return a.Order - b.Order;
                                                });

                                                if(this.VisitId !== ''){
                                                    this.$http.ejecutar('GET', 'VisitStatusMasterActivity-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusMasterActivity => {
                                                        if(responseVisitStatusMasterActivity.status === 200){
                                                            this.StatusMaster = responseVisitStatusMasterActivity.data.data;
                                                            if(this.visitaTerminalId === 0){

                                                                this.formTerminalVisity.ConditionPlanningId = this.itinerarySelected.ConditionPlanningId;
                                                                this.formTerminalVisity.OperationId = this.itinerarySelected.OperationId;
                                                                this.formTerminalVisity.PortOriginId = this.itinerarySelected.PortOriginId;
                                                                this.formTripVisity.shippingAgentId = this.itinerarySelected.ShippingAgencyId;

                                                                this.JsonActivity = JsonActivity.map((item) => Object.assign({}, item, {
                                                                    Name:'',
                                                                    Observation:'',
                                                                    Date: item.Date == "" ? "" : item.Date,
                                                                    Hour: item.Hour == "" ? "" : item.Hour,
                                                                    MinDate: (item.MinDate),
                                                                    MaxDate: (item.MaxDate),
                                                                    Quantity: 0,
                                                                    bandera:false,
                                                                    FgDisabled: false,
                                                                }));
                                                                if(this.StatusMaster.length !== 0){
                                                                    this.JsonActivity.map(async (item) => {
                                                                        item.FgDisabled = true;
                                                                        this.StatusMaster.map(async (item2) => {
                                                                            if(item2.FgTerminal && item.VisitActivityId === item2.VisitActivityId){
                                                                                item.FgDisabled = false;
                                                                            }
                                                                        })
                                                                    })
                                                                }

                                                                this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                                            }else{
                                                                this.$http.ejecutar('GET', 'VisitTerminal-by-VisitTerminalId', { VisitTerminalId:this.visitaTerminalId }, '').then(responseVisitiId => {
                                                                    if(responseVisitiId.status === 200){
                                                                        let informationVisit = responseVisitiId.data.data[0];

                                                                        this.formTerminalVisity.CaptainName = informationVisit.CaptainName;
                                                                        this.formTerminalVisity.ConditionPlanningId = informationVisit.ConditionPlanningId;
                                                                        this.formTerminalVisity.ProtectionLevelId = informationVisit.ProtectionLevelId;
                                                                        this.formTerminalVisity.OperationId = informationVisit.OperationId;
                                                                        this.formTerminalVisity.PortId = informationVisit.PortId;
                                                                        this.formTerminalVisity.PortOriginId = informationVisit.PortOriginId;
                                                                        this.formTerminalVisity.PortDestinationId = informationVisit.PortDestinationId;
                                                                        this.formTerminalVisity.BerthId = informationVisit.BerthId;
                                                                        this.formTerminalVisity.TerminalId = informationVisit.TerminalId;
                                                                        this.formTerminalVisity.SspNumber = informationVisit.SspNumber;
                                                                        this.formTerminalVisity.SspYear = informationVisit.SspYear;
                                                                        this.formTerminalVisity.SspPlanedTon = informationVisit.SspPlanedTon ? informationVisit.SspPlanedTon.toFixed(2) : 0;
                                                                        
                                                                        if(this.bulkCarrier == 2){
                                                                            let inputSspPlanedTon = this.$refs.sspPlanedTonInput.$el.querySelector("input");
                                                                            inputSspPlanedTon.value = informationVisit.SspPlanedTon ? informationVisit.SspPlanedTon.toFixed(2) : 0;
                                                                        }
                                                                        const id = this.formTerminalVisity.PortId;

                                                                        const index = this.ListPort.map(function(e) {
                                                                            return e.PortId; 
                                                                        }).indexOf(id);
                                                                        if(index !== -1){
                                                                            this.ListBerth = this.ListPort[index].BerthJson.BerthJson;
                                                                        }

                                                                        informationVisit.TerminalActivityJson.sort(function (a, b) {
                                                                            return a.Order - b.Order;
                                                                        });

                                                                        informationVisit.TerminalActivityJson.map(async (item1)=>{
                                                                            JsonActivity.map(async (item2) => {
                                                                                if(item1.VisitActivityId === item2.VisitActivityId){
                                                                                    item2.Name = item1.Name;
                                                                                    item2.Observation = item1.Observation;
                                                                                    item2.ActivityDate = DateFormater.formatDateWithSlash(item1.ActivityDate)+' '+DateFormater.formatOnlyTime(item1.ActivityTime)
                                                                                    item2.Quantity = item1.Quantity ? item1.Quantity  : 0
                                                                                }
                                                                            })
                                                                        })
                                                                        this.JsonActivity = JsonActivity.map((item) => Object.assign({}, item, {
                                                                            Date: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(item.ActivityDate) : "",
                                                                            Hour: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(item.ActivityDate) : "",
                                                                            MinDate: (item.MinDate),
                                                                            MaxDate: (item.MaxDate),
                                                                            bandera:false,
                                                                            FgDisabled:false,
                                                                        }));

                                                                        if(this.StatusMaster.length !== 0){
                                                                            this.JsonActivity.map(async (item) => {
                                                                                item.FgDisabled = true;
                                                                                this.StatusMaster.map(async (item2) => {
                                                                                    if(item2.FgTerminal && item.VisitActivityId === item2.VisitActivityId){
                                                                                        item.FgDisabled = false;
                                                                                    }
                                                                                })
                                                                            })
                                                                        }

                                                                        //tab3

                                                                        this.formTripVisity.shippingAgentId = informationVisit.ShippingLineAgencyId;
                                                                        this.formTripVisity.shippingAgentId = this.formTripVisity.shippingAgentId == null || this.formTripVisity.shippingAgentId == '' || this.formTripVisity.shippingAgentId == undefined ? this.itinerarySelected.ShippingAgencyId : this.formTripVisity.shippingAgentId;
                                                                        this.formTripVisity.portOperatorId = informationVisit.PortOperatorId;
                                                                        this.formTripVisity.ServiceCode = informationVisit.ServiceCode;
                                                                        this.formTripVisity.ShippingLineAgencyName = informationVisit.ShippingLineAgencyName;
                                                                        this.formTripVisity.voyageArrival = informationVisit.VoyageArrival;
                                                                        this.formTripVisity.voyageDeparture = informationVisit.VoyageDeparture;

                                                                       // this.ListVisitLine = informationVisit.VisitLineJson;
                                                                        if(informationVisit.VisitLineJson.length > 0){
                                                                            this.ListVisitLine = informationVisit.VisitLineJson;
                                                                        }
                                                                        //tab2 tab 4
                                                                        this.SheduleArrivalActivityJson=informationVisit.SheduleArrivalActivityJson;
                                                                        this.SheduleDepartureActivityJson=informationVisit.SheduleDepartureActivityJson;
                                                                        this.$store.state.visitas.apiStateForm = ENUM.INIT;

                                                                        this.StatusFormulario = informationVisit.SheduleArrivalActivityJson.length !== 0 ? 
                                                                            ((informationVisit.TerminalActivityJson.length !== 0) ? 3 : (informationVisit.VoyageActivityJson.length !== 0 ? 2 : 1))
                                                                            : 0;
                                                                        this.$refs.wizard.changeTab(0,this.StatusFormulario);
                                                                    }else{
                                                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                                    }
                                                                }).catch(err => {
                                                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                                    this.$store.commit('visitas/messageMutation', err);
                                                                });
                                                            }
                                                        }else{
                                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                        }
                                                    }).catch(err => {
                                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                        this.$store.commit('visitas/messageMutation', err);
                                                    });
                                                    
                                                }else{
                                                    if(this.visitaTerminalId === 0){
                                                        this.formTerminalVisity.ConditionPlanningId = this.itinerarySelected.ConditionPlanningId;
                                                        this.formTerminalVisity.OperationId = this.itinerarySelected.OperationId;
                                                        this.formTerminalVisity.PortOriginId = this.itinerarySelected.PortOriginId;
                                                        this.formTripVisity.shippingAgentId = this.itinerarySelected.ShippingAgencyId;

                                                        this.JsonActivity = JsonActivity.map((item) => Object.assign({}, item, {
                                                            Name:'',
                                                            Observation:'',
                                                            Date: item.Date == "" ? "" : item.Date,
                                                            Hour: item.Hour == "" ? "" : item.Hour,
                                                            MinDate: (item.MinDate),
                                                            MaxDate: (item.MaxDate),
                                                            Quantity: 0,
                                                            bandera:false,
                                                            FgDisabled:false,
                                                        }));

                                                        this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                                    }else{
                                                        this.$http.ejecutar('GET', 'VisitTerminal-by-VisitTerminalId', { VisitTerminalId:this.visitaTerminalId }, '').then(responseVisitiId => {
                                                            if(responseVisitiId.status === 200){
                                                                let informationVisit = responseVisitiId.data.data[0];

                                                                this.formTerminalVisity.CaptainName = informationVisit.CaptainName;
                                                                this.formTerminalVisity.ConditionPlanningId = informationVisit.ConditionPlanningId;
                                                                this.formTerminalVisity.ProtectionLevelId = informationVisit.ProtectionLevelId;
                                                                this.formTerminalVisity.OperationId = informationVisit.OperationId;
                                                                this.formTerminalVisity.PortId = informationVisit.PortId;
                                                                this.formTerminalVisity.PortOriginId = informationVisit.PortOriginId;
                                                                this.formTerminalVisity.PortDestinationId = informationVisit.PortDestinationId;
                                                                this.formTerminalVisity.BerthId = informationVisit.BerthId;
                                                                this.formTerminalVisity.TerminalId = informationVisit.TerminalId;
                                                                this.formTerminalVisity.SspNumber = informationVisit.SspNumber;
                                                                this.formTerminalVisity.SspYear = informationVisit.SspYear;
                                                                this.formTerminalVisity.SspPlanedTon = informationVisit.SspPlanedTon ? informationVisit.SspPlanedTon.toFixed(2) : 0;
                                                                
                                                                if(this.bulkCarrier == 2){
                                                                    let inputSspPlanedTon = this.$refs.sspPlanedTonInput.$el.querySelector("input");
                                                                    inputSspPlanedTon.value = informationVisit.SspPlanedTon ? informationVisit.SspPlanedTon.toFixed(2) : 0;
                                                                }
                                                                const id = this.formTerminalVisity.PortId;

                                                                const index = this.ListPort.map(function(e) {
                                                                    return e.PortId; 
                                                                }).indexOf(id);
                                                                if(index !== -1){
                                                                    this.ListBerth = this.ListPort[index].BerthJson.BerthJson;
                                                                }

                                                                informationVisit.TerminalActivityJson.sort(function (a, b) {
                                                                    return a.Order - b.Order;
                                                                });

                                                                informationVisit.TerminalActivityJson.map(async (item1)=>{
                                                                    JsonActivity.map(async (item2) => {
                                                                        if(item1.VisitActivityId === item2.VisitActivityId){
                                                                            item2.Name = item1.Name;
                                                                            item2.Observation = item1.Observation;
                                                                            item2.ActivityDate = DateFormater.formatDateWithSlash(item1.ActivityDate)+' '+DateFormater.formatOnlyTime(item1.ActivityTime)
                                                                            item2.Quantity = item1.Quantity ? item1.Quantity : 0;
                                                                        }
                                                                    })
                                                                }) 
                                                                this.JsonActivity = JsonActivity.map((item) => Object.assign({}, item, {
                                                                    Date: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(item.ActivityDate) : "",
                                                                    Hour: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(item.ActivityDate) : "",
                                                                    MinDate: (item.MinDate),
                                                                    MaxDate: (item.MaxDate),
                                                                    bandera:false,
                                                                    FgDisabled:false,
                                                                }));

                                                                //tab2
                                                                this.formTripVisity.shippingAgentId = informationVisit.ShippingLineAgencyId;
                                                                this.formTripVisity.shippingAgentId = this.formTripVisity.shippingAgentId == null || this.formTripVisity.shippingAgentId == '' || this.formTripVisity.shippingAgentId == undefined ? this.itinerarySelected.ShippingAgencyId : this.formTripVisity.shippingAgentId;
                                                                this.formTripVisity.portOperatorId = informationVisit.PortOperatorId;
                                                                this.formTripVisity.ServiceCode = informationVisit.ServiceCode;
                                                                this.formTripVisity.ShippingLineAgencyName = informationVisit.ShippingLineAgencyName;
                                                                this.formTripVisity.voyageArrival = informationVisit.VoyageArrival;
                                                                this.formTripVisity.voyageDeparture = informationVisit.VoyageDeparture;
                                                                
                                                               // this.ListVisitLine = informationVisit.VisitLineJson;
                                                                if(informationVisit.VisitLineJson.length > 0){
                                                                    this.ListVisitLine = informationVisit.VisitLineJson;
                                                                }

                                                                //tab3
                                                                this.SheduleArrivalActivityJson=informationVisit.SheduleArrivalActivityJson;
                                                                this.SheduleDepartureActivityJson=informationVisit.SheduleDepartureActivityJson;
                                                                this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                                            }else{
                                                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                            }
                                                        }).catch(err => {
                                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                            this.$store.commit('visitas/messageMutation', err);
                                                        });
                                                    }
                                                }
                                                
                                            }else{
                                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                            }
                                        }).catch(err => {
                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                            this.$store.commit('visitas/messageMutation', err);
                                        });
                                    }else{
                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                    }
                                }).catch(err => {
                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                    this.$store.commit('visitas/messageMutation', err);
                                });
                            }else{
                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                            }
                        }).catch(err => {
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                            this.$store.commit('visitas/messageMutation', err);
                        });
                    }else{
                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                    }
                }).catch(err => {
                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                    this.$store.commit('visitas/messageMutation', err);
                });
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', err);
        });
    }
    //methods
    async function handleTabChange(prevIndex, nextIndex){
        if(nextIndex === 0){
            if(this.StatusMaster.length !== 0){
                this.JsonActivity.map(async (item) => {
                    item.FgDisabled = true;
                    this.StatusMaster.map(async (item2) => {
                        if(item2.FgTerminal && item.VisitActivityId === item2.VisitActivityId){
                            item.FgDisabled = false;
                        }
                    })
                })
            }
            
        }else if(nextIndex === 1){
            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar('GET', 'VisitTerminal-by-VisitTerminalId', { VisitTerminalId: this.visitaTerminalId }, '').then(responseVisitActivictyV => {
                if(responseVisitActivictyV.status === 200){
                    this.$http.ejecutar('GET', 'VisitSheduleArriveActivicty-list', { ItineraryId: this.ItineraryId }, '').then(responseSheduleActivicty => {
                        if(responseSheduleActivicty.status === 200){
                            let informationVisit = responseVisitActivictyV.data.data[0];
                            
                            this.SheduleArrivalActivityJson=informationVisit.SheduleArrivalActivityJson;
                            let ArrivalJson = responseSheduleActivicty.data.data[0].ArriveJson
                            
                            ArrivalJson.sort(function (a, b) {
                                return a.Order - b.Order;
                            });

                            this.SheduleArrivalActivityJson.map(async (item1)=>{
                                ArrivalJson.map(async (item2) => {
                                    if(item1.VisitActivityId === item2.VisitActivityId){
                                        item2.Name = item1.Name;
                                        item2.Observation = item1.Observation;
                                        item2.ActivityDate = DateFormater.formatDateWithSlash(item1.ActivityDate)+' '+DateFormater.formatOnlyTime(item1.ActivityTime)
                                    }
                                })
                            })

                            ArrivalJson.map(async (item) => {
                                item.FgDisabled = true;
                                this.StatusMaster.map(async (item2) => {
                                    if(item2.FgArrive && item.VisitActivityId === item2.VisitActivityId){
                                        item.FgDisabled = false;
                                    }
                                })
                            })

                            this.JsonVisitActivityShedule.ArriveJson = ArrivalJson.map((item) => Object.assign({}, item, {
                                Date: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(item.ActivityDate) : "",
                                Hour: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(item.ActivityDate) : "",
                                MinDate: (item.MinDate),
                                MaxDate: (item.MaxDate),
                                bandera: false
                            }));

                            
                            
                            this.$store.state.visitas.apiStateForm = ENUM.INIT;
                        }else{
                            this.$refs.wizard.prevTab();
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$refs.wizard.prevTab();
                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        this.$store.commit('visitas/messageMutation', err);
                    });
                }else{
                    this.$refs.wizard.prevTab();
                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$refs.wizard.prevTab();
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                this.$store.commit('visitas/messageMutation', err);
            });
        }
        else if(nextIndex === 2){
            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            //TpClientId anterior 798ec5fd-1aaa-4fd1-9785-b23096ec709a
            this.$http.ejecutar('GET', 'Client-list-by-activity', { TpClientId: "10fafb97-1575-4c71-8cdb-7f8f3c0c1245",Filter:"ACTIVO" }, '').then(responseAgenteNaviero => {
                if(responseAgenteNaviero.status === 200){
                    this.$http.ejecutar('GET', 'Client-list-by-activity', { TpClientId: "c49b7e5e-5900-ec11-a872-000d3a0dedc7",Filter:"ACTIVO" }, '').then(responseOperadorPortuario => {
                        if(responseOperadorPortuario.status === 200){
                            this.$http.ejecutar('GET', 'ServiceItineraryHist-list', { ItineraryId: this.ItineraryId }, '').then(responseServiceItineraryHist => {
                                if(responseServiceItineraryHist.status === 200){
                                    this.$http.ejecutar('GET', 'VisitVoyageActivicty-list', { ItineraryId: this.ItineraryId }, '').then(responseVoyageActivicty => {
                                        if(responseVoyageActivicty.status === 200){
                                            this.$http.ejecutar('GET', 'VisitTerminal-by-VisitTerminalId', { VisitTerminalId:this.visitaTerminalId }, '').then(responeVisitiId => {
                                                if(responeVisitiId.status === 200){
                                                    let informationVisit = responeVisitiId.data.data[0];
                                                    this.ListAgenteNaviero = responseAgenteNaviero.data.data;
                                                    this.ListOperadorPortuario = responseOperadorPortuario.data.data;
                                                    this.ListServiceItineraryHist = responseServiceItineraryHist.data.data;

                                                    this.formTripVisity.shippingAgentId = informationVisit.ShippingLineAgencyId;
                                                    this.formTripVisity.shippingAgentId = this.formTripVisity.shippingAgentId == null || this.formTripVisity.shippingAgentId == '' || this.formTripVisity.shippingAgentId == undefined ? this.itinerarySelected.ShippingAgencyId : this.formTripVisity.shippingAgentId;
                                                    this.formTripVisity.portOperatorId = informationVisit.PortOperatorId;
                                                    this.formTripVisity.ServiceCode = informationVisit.ServiceCode;
                                                    this.formTripVisity.ShippingLineAgencyName = informationVisit.ShippingLineAgencyName;
                                                    this.formTripVisity.voyageArrival = informationVisit.VoyageArrival;
                                                    this.formTripVisity.voyageDeparture = informationVisit.VoyageDeparture;

                                                    let JsonActivity = responseVoyageActivicty.data.data[0].JsonActivity;
                                                    JsonActivity.sort(function (a, b) {
                                                        return a.Order - b.Order;
                                                    });

                                                    informationVisit.VoyageActivityJson.sort(function (a, b) {
                                                        return a.Order - b.Order;
                                                    });

                                                    informationVisit.VoyageActivityJson.map(async (item1)=>{
                                                        JsonActivity.map(async (item2) => {
                                                            if(item1.VisitActivityId === item2.VisitActivityId){
                                                                item2.Name = item1.Name;
                                                                item2.Observation = item1.Observation;
                                                                item2.ActivityDate = DateFormater.formatDateWithSlash(item1.ActivityDate)+' '+DateFormater.formatOnlyTime(item1.ActivityTime)
                                                            }
                                                        })
                                                    })
                                                    this.JsonVisitActivityVoyage = JsonActivity.map((item) => Object.assign({}, item, {
                                                        Date: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(item.ActivityDate) : "",
                                                        Hour: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(item.ActivityDate) : "",
                                                        MinDate: (item.MinDate),
                                                        MaxDate: (item.MaxDate),
                                                        FgDisabled:false,
                                                        bandera: false,
                                                    }));

                                                    if(this.StatusMaster.length !== 0){
                                                        this.JsonVisitActivityVoyage.map(async (item) => {
                                                            item.FgDisabled = true;
                                                            this.StatusMaster.map(async (item2) => {
                                                                if(item2.FgVoyage && item.VisitActivityId === item2.VisitActivityId){
                                                                    item.FgDisabled = false;
                                                                }
                                                            })
                                                        })
                                                    }
                                                    
                                                    if(this.ListVisitLine.length > 0){
                                                        this.ListVisitLine = informationVisit.VisitLineJson;
                                                    }
                                                    
                                                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                                }else{
                                                    this.$refs.wizard.prevTab();
                                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                }
                                            }).catch(err => {
                                                this.$refs.wizard.prevTab();
                                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                this.$store.commit('visitas/messageMutation', err);
                                            });
                                        }else{
                                            this.$refs.wizard.prevTab();
                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                        }
                                    }).catch(err => {
                                        this.$refs.wizard.prevTab();
                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                        this.$store.commit('visitas/messageMutation', err);
                                    });
                                }else{
                                    this.$refs.wizard.prevTab();
                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                }
                            }).catch(err => {
                                this.$refs.wizard.prevTab();
                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                this.$store.commit('visitas/messageMutation', err);
                            });
                        }else{
                            this.$refs.wizard.prevTab();
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$refs.wizard.prevTab();
                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        this.$store.commit('visitas/messageMutation', err);
                    });
                }else{
                    this.$refs.wizard.prevTab();
                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$refs.wizard.prevTab();
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                this.$store.commit('visitas/messageMutation', err);
            });
        }
        else if(nextIndex === 3){
            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar('GET', 'VisitTerminal-by-VisitTerminalId', { VisitTerminalId: this.visitaTerminalId }, '').then(responseVisitActivictyV => {
                if(responseVisitActivictyV.status === 200){
                    this.$http.ejecutar('GET', 'VisitSheduleDepartureActivicty-list', { ItineraryId: this.ItineraryId }, '').then(responseSheduleActivicty => {
                        if(responseSheduleActivicty.status === 200){
                            let informationVisit = responseVisitActivictyV.data.data[0];
                            
                            this.SheduleDepartureActivityJson=informationVisit.SheduleDepartureActivityJson;
                            
                            let DepartureJson = responseSheduleActivicty.data.data[0].ArriveJson
                            
                            DepartureJson.sort(function (a, b) {
                                return a.Order - b.Order;
                            });

                            this.SheduleDepartureActivityJson.map(async (item1)=>{
                                DepartureJson.map(async (item2) => {
                                    if(item1.VisitActivityId === item2.VisitActivityId){
                                        item2.Name = item1.Name;
                                        item2.Observation = item1.Observation;
                                        item2.ActivityDate = DateFormater.formatDateWithSlash(item1.ActivityDate)+' '+DateFormater.formatOnlyTime(item1.ActivityTime)
                                    }
                                })
                            })

                            DepartureJson.map(async (item) => {
                                item.FgDisabled = true;
                                this.StatusMaster.map(async (item2) => {
                                    if(item2.FgDeparture && item.VisitActivityId === item2.VisitActivityId){
                                        item.FgDisabled = false;
                                    }
                                })
                            })

                            this.JsonVisitActivityShedule.DepartureJson = DepartureJson.map((item) => Object.assign({}, item, {
                                Date: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(item.ActivityDate) : "",
                                Hour: item.ActivityDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(item.ActivityDate) : "",
                                MinDate: (item.MinDate),
                                MaxDate: (item.MaxDate),
                                bandera:false,
                            }));
                            
                            this.$store.state.visitas.apiStateForm = ENUM.INIT;
                        }else{
                            this.$refs.wizard.prevTab();
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$refs.wizard.prevTab();
                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        this.$store.commit('visitas/messageMutation', err);
                    });
                }else{
                    this.$refs.wizard.prevTab();
                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$refs.wizard.prevTab();
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                this.$store.commit('visitas/messageMutation', err);
            });
        }
    }
    function setLoading(value) {
        this.loadingWizard = value
    }
    function validacionFechas(fechaInicial,fechaFinal,fechaReal,nombreCampo){
        let opened = false;
        if(this.VisitId !== ''){
            /*opened = fechaReal < this.Ata || fechaReal > this.Atd;
            if(opened){
                return 'El rango de la fecha es inválido, solo se puede seleccionar la fecha en el rango de: '+moment(this.Ata).format('DD/MM/YYYY HH:mm')+' - '+moment(this.Atd).format('DD/MM/YYYY HH:mm')+' en el campo '+nombreCampo;
            }
            else{ return ""; }*/
            return "";
        }else{
            let fechaRestada = moment(moment(this.Ata).subtract(5, 'days')).toDate();
            let fechaSumada = moment(moment(this.Atd).add(5, 'days')).toDate();
            opened = fechaReal < fechaRestada || fechaReal > fechaSumada;
            if(opened){
                return 'El rango de la fecha es inválido, solo se puede seleccionar la fecha en el rango de: '+moment(fechaRestada).format('DD/MM/YYYY HH:mm')+' - '+moment(fechaSumada).format('DD/MM/YYYY HH:mm')+' en el campo '+nombreCampo;
            }
            else{ return ""; }
        }
        
        /*else{
            let fechaActual = new Date();
            if((fechaActual >= this.Ata && fechaActual <= this.Atd) && (fechaActual >= fechaInicial && fechaActual <= fechaFinal)){
                let fechaExtra = moment(moment(fechaActual).add(2, 'days')).toDate();
                if(fechaReal < fechaExtra){
                    return 'El rango de la fecha es inválido, solo se pueden fechas que sean 2 días despues del actual en el campo '+nombreCampo;
                }else{
                    return '';
                }
            }else{
                return '';
            }
        }*/
    }
    function formatHourMMHH(e) {
        e.preventDefault();
        return false;
    }
    function SoloNumeros(e) {
        var regex = new RegExp('^[0-9.,]+$');
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }
    function validationDate(ActivityDate,data,JsonActivity){
        let messagesError = "";
        if((data.Name !== null && data.Name !== "" && data.Name !== undefined) && data.FgName == true && data.FgRequired == true && data.Name.length > 250 ){
            messagesError = messagesError == '' ? (this.$t('label.max')+"250"+this.$t('label.chars')+ this.titleItemsActivity(data)) : messagesError;
        }
        let minDate = moment(data.MinDate,"DD/MM/YYYY HH:mm").toDate()
        if(ActivityDate < minDate){
            messagesError = messagesError == '' ? (this.$t('label.dateInvalidLessIn')+data.MinDate+this.$t('label.In')+ this.titleItemsActivity(data)) : messagesError;
        }
        
        let maxDate = moment(data.MaxDate,"DD/MM/YYYY HH:mm").toDate()
        if(ActivityDate > maxDate){
            messagesError = messagesError == '' ? (this.$t('label.dateInvalidHigherIn')+data.MaxDate+this.$t('label.In')+ this.titleItemsActivity(data)) : messagesError;
        }
        if(data.FgQuantity && data.Quantity <= 0){
            messagesError = messagesError == '' ? (this.$t('label.ammounInvalidZero')+this.$t('label.In')+ this.titleItemsActivity(data)) : messagesError;
        }
        messagesError = messagesError == '' ? this.validacionFechas(minDate,maxDate,ActivityDate,this.titleItemsActivity(data)) : messagesError;
        if(messagesError === ''){
            data.Validations.map(async (items) => {
                if(messagesError === ''){
                    messagesError = this.validationsDates(ActivityDate,items.Condition,this.titleItemsActivity(data),items.VisitActivityChildId,JsonActivity);
                }
            })
        }
        return messagesError;
    }
    async function registerDataTerminal(){
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.$v.formTerminalVisity.$touch();
                if (this.$v.formTerminalVisity.$pending || this.$v.formTerminalVisity.$error){ resolve(false); return};
                let messagesError = '';
                let VisitActivityJson = [];
                this.JsonActivity.map(async (data) => {
                    if(messagesError === ''){
                        if(!data.FgDisabled){
                            if((data.Date === "" || data.Hour === "") && data.FgRequired){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if(((data.Date !== "" && data.Hour === "") || (data.Date === "" && data.Hour !== ""))){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if((data.Date === "" && data.Hour === "") && !data.FgRequired){
                                VisitActivityJson.push({
                                    VisitActivityId: data.VisitActivityId,
                                    Name: data.Name,
                                    Observation: data.Observation,
                                    ActivityDate: "",
                                    Quantity: data.Quantity,
                                    FgBerth: this.checkFgBerth(data) == '1' ? 1:0,
                                })
                                // if(data.FgInspection) {
                                //     VisitActivityJson[VisitActivityJson.length-1].FgBerth = this.berth;
                                // }
                                if(data.bandera){   
                                    this.formTerminalVisity.tecleado = true;
                                }
                            }else if(data.Date !== "" && data.Hour !== ""){
                                let isValid = moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm", true).isValid();
                                if(this.visitaTerminalId == 0){
                                    if(data.bandera && !data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,this.JsonActivity) : messagesError;
                                            this.formTerminalVisity.tecleado = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }else if(data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,this.JsonActivity) : messagesError;
                                            this.formTerminalVisity.tecleado = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }
                                }else if(data.bandera){
                                    if(isValid){
                                        let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                        messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,this.JsonActivity) : messagesError;
                                        this.formTerminalVisity.tecleado = true;
                                    }else{
                                        messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                    }
                                }
                                if(isValid){
                                    let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                    VisitActivityJson.push({
                                        VisitActivityId: data.VisitActivityId,
                                        Name: data.Name,
                                        Observation: data.Observation,
                                        ActivityDate: moment(ActivityDate).format('YYYY-MM-DD HH:mm'),
                                        Quantity: data.Quantity,
                                        FgBerth: this.checkFgBerth(data) == '1' ? 1:0,
                                    })
                                }
                            }
                        }
                    }
                })
                if(messagesError !== ''){
                    this.$notify({
                        group: 'container',
                        type: 'error',
                        ignoreDuplicates:false,
                        title: 'ERROR',
                        text: messagesError
                    });
                    resolve(false);
                }else{
                    if(this.visitaTerminalId === 0){
                        if(this.bulkCarrier == 2){
                            this.formTerminalVisity.SspPlanedTon = Number.parseFloat(this.formTerminalVisity.SspPlanedTon.replace(".", "").replace(",", "."));
                        }
                        let VisitJson = {
                            VisitId: this.VisitId === '' ? '' : this.VisitId,
                            Called: this.token,
                            StowagePlanningId:this.StowagePlanningId,
                            ItineraryId:this.ItineraryId,
                            OperationId:this.formTerminalVisity.OperationId,
                            ConditionPlanningId:this.formTerminalVisity.ConditionPlanningId,
                            ProtectionLevelId:this.formTerminalVisity.ProtectionLevelId,
                            CaptainName:this.formTerminalVisity.CaptainName,
                            PortId:this.formTerminalVisity.PortId,
                            PortOriginId:this.formTerminalVisity.PortOriginId,
                            PortDestinationId:this.formTerminalVisity.PortDestinationId,
                            BerthId:this.formTerminalVisity.BerthId,
                            TerminalId:this.formTerminalVisity.TerminalId,
                            VisitActivityJson:VisitActivityJson,
                            SspNumber: this.formTerminalVisity.SspNumber,
                            SspYear: this.formTerminalVisity.SspYear,
                            SspPlanedTon: this.formTerminalVisity.SspPlanedTon,
                        }
                        let banderitaVisita = this.VisitId !== '' ? true : false;
                        this.$store.state.visitas.apiStateForm = ENUM.LOADING;

                        this.$http.ejecutar("POST", "Visit-insert", VisitJson, { root: 'VisitJson' })
                        .then(response => {
                            if(response.status === 200){
                                let res = [...response.data.data];
                                this.$notify({
                                    group: 'container',
                                    text: res[0].Response,
                                    type: "success"
                                });
                                
                                this.formTerminalVisity.tecleado = false;
                                this.JsonActivity.map(async (data) => {
                                    data.bandera = false;
                                });
                                res[0].Called = this.token;
                                this.$store.state.visitas.VisitId =  res[0].VisitId;
                                this.StatusFormulario = this.StatusFormulario === 0 ? 1 : this.StatusFormulario;
                                this.$emit('asignarTerminal', res[0]);

                                if(this.StatusMaster.length === 0){
                                    this.$http.ejecutar('GET', 'VisitStatusMasterActivity-by-VisitId', { VisitId:res[0].VisitId }, '').then(responseVisitStatusMasterActivity => {
                                        if(responseVisitStatusMasterActivity.status === 200){
                                            if(banderitaVisita){
                                                this.StatusMaster = responseVisitStatusMasterActivity.data.data;
                                                this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                                resolve(true);
                                            }else{
                                                this.$http.ejecutar('GET', 'Visit-by-VisitId', { VisitId:res[0].VisitId }, '').then(responseVisitStatusId => {
                                                    if(responseVisitStatusId.status === 200){
                                                        this.StatusMaster = responseVisitStatusMasterActivity.data.data;
                                                        this.$store.state.visitas.apiStateForm = ENUM.INIT;

                                                        let responseId = responseVisitStatusId.data.data[0];
                                                        this.itinerarySelected.VisitStatus = responseId.VisitStatus;   
                                                        this.itinerarySelected.VisitStatusEs = responseId.VisitStatusEs;
                                                        this.itinerarySelected.VisitStatusEn = responseId.VisitStatusEn;

                                                        this.itinerarySelected.LabelDeparture = responseId.LabelDeparture;
                                                        this.itinerarySelected.Departure = responseId.Departure;
                                                        resolve(true);
                                                    }else{
                                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                        resolve(false);
                                                    }
                                                }).catch(err => {
                                                    resolve(false);
                                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                    this.$store.commit('visitas/messageMutation', err);
                                                });
                                            }
                                        }else{
                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                            resolve(false);
                                        }
                                    }).catch(err => {
                                        resolve(false);
                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                        this.$store.commit('visitas/messageMutation', err);
                                    });
                                }else{
                                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                    resolve(true);
                                }
                            }else{
                                this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                resolve(true);
                            }
                            /*this.resetsInput();
                            this.$store.state.visitas.collapseTerminal = false;
                            this.$emit('submited', res[0]);*/
                        }).catch((err) => {
                            console.log(err);
                            this.$store.commit('visitas/messageMutation', err);
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                            resolve(false);
                        });
                    }else{
                        if(this.formTerminalVisity.tecleado){
                            if(this.bulkCarrier == 2){
                                this.formTerminalVisity.SspPlanedTon = Number.parseFloat(this.formTerminalVisity.SspPlanedTon.replace(".", "").replace(",", "."));
                            }
                            let VisitJson = {
                                VisitId: this.$store.state.visitas.VisitId,
                                VisitTerminalId: this.visitaTerminalId,
                                Called: this.itemSelected.Called !== "" && this.itemSelected.Called !== null ? this.itemSelected.Called : this.token,
                                StowagePlanningId:this.StowagePlanningId,
                                ItineraryId:this.ItineraryId,
                                OperationId:this.formTerminalVisity.OperationId,
                                ConditionPlanningId:this.formTerminalVisity.ConditionPlanningId,
                                ProtectionLevelId:this.formTerminalVisity.ProtectionLevelId,
                                CaptainName:this.formTerminalVisity.CaptainName,
                                PortId:this.formTerminalVisity.PortId,
                                PortOriginId:this.formTerminalVisity.PortOriginId,
                                PortDestinationId:this.formTerminalVisity.PortDestinationId,
                                BerthId:this.formTerminalVisity.BerthId,
                                TerminalId:this.formTerminalVisity.TerminalId,
                                VisitActivityJson:VisitActivityJson,
                                SspNumber: this.formTerminalVisity.SspNumber,
                                SspYear: this.formTerminalVisity.SspYear,
                                SspPlanedTon: this.formTerminalVisity.SspPlanedTon,
                            }
                            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                            this.$http.ejecutar("PUT", "VisitTerminal-update", VisitJson, { root: 'VisitJson' })
                            .then(response => {
                                let res = [...response.data.data];
                                this.$notify({
                                    group: 'container',
                                    text: res[0].Response,
                                    type: "success"
                                });
                                resolve(true);
                                this.formTerminalVisity.tecleado = false;
                                this.JsonActivity.map(async (data) => {
                                    data.bandera = false;
                                });
                                this.StatusFormulario = this.StatusFormulario === 0 ? 1 : this.StatusFormulario;
                                this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                /*this.resetsInput();
                                this.$store.state.visitas.collapseTerminal = false;
                                this.$emit('submited', res[0]);*/
                            }).catch((err) => {
                                this.$store.commit('visitas/messageMutation', err);
                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                resolve(false);
                            });
                        }else{
                            resolve(true);
                        }
                    }
                }
                
                
            }, 1000)
        })
    }
    async function registerDataVoyage(){
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.$v.formTripVisity.$touch();
                if (this.$v.formTripVisity.$pending || this.$v.formTripVisity.$error){ resolve(false); return};
                let id = this.visitaTerminalId;
                let messagesError = '';
                let VisitActivityJson = [];
                this.JsonVisitActivityVoyage.map(async (data) => {
                    if(messagesError === ''){
                        if(!data.FgDisabled){
                            if((data.Date === "" || data.Hour === "") && data.FgRequired){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if(((data.Date !== "" && data.Hour === "") || (data.Date === "" && data.Hour !== ""))){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if((data.Date === "" && data.Hour === "") && !data.FgRequired){
                                VisitActivityJson.push({
                                    VisitActivityId: data.VisitActivityId,
                                    Name: data.Name,
                                    Observation: data.Observation,
                                    ActivityDate: "",
                                })
                                if(data.bandera){   
                                    this.formTripVisity.tecleado = true;
                                }
                            }else if(data.Date !== "" && data.Hour !== ""){
                                let isValid = moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm", true).isValid();
                                if(this.StatusFormulario < 3){
                                    if(data.bandera && !data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,this.JsonVisitActivityVoyage) : messagesError;
                                            this.formTripVisity.tecleado = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }else if(data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,this.JsonVisitActivityVoyage) : messagesError;
                                            this.formTripVisity.tecleado = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }
                                }else if(data.bandera){
                                    if(isValid){
                                        let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                        messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,this.JsonVisitActivityVoyage) : messagesError;
                                        this.formTripVisity.tecleado = true;
                                    }else{
                                        messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                    }
                                }
                                if(isValid){
                                    let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                    VisitActivityJson.push({
                                        VisitActivityId: data.VisitActivityId,
                                        Name: data.Name,
                                        Observation: data.Observation,
                                        ActivityDate: moment(ActivityDate).format('YYYY-MM-DD HH:mm'),
                                    })
                                }
                            }
                        }
                    }
                })
                if(messagesError !== ''){
                    this.$notify({
                        group: 'container',
                        type: 'error',
                        ignoreDuplicates:false,
                        title: 'ERROR',
                        text: messagesError
                    });
                    resolve(false);
                }else{
                    if(this.formTripVisity.tecleado){
                        if(this.bulkCarrier == 1){
                            let rowData = [];
                            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

                            if(rowData.length !== 0){
                                let jsonLine = [];
                                rowData.map(async (item) => {
                                    jsonLine.push({
                                        VisitLineId:item.VisitLineId,
                                        ServiceLineId:item.ServiceLineId,
                                        AliasVoyage:item.AliasShippingLineVoyage,
                                    })
                                })
                                
                                let VisitJson = {
                                    VisitTerminalId: this.visitaTerminalId,
                                    VoyageArrival:this.formTripVisity.voyageArrival,
                                    VoyageDeparture:this.formTripVisity.voyageDeparture,
                                    ShippingLineAgencyId:this.formTripVisity.shippingAgentId,
                                    PortOperatorId:this.formTripVisity.portOperatorId,
                                    VisitActivityJson:VisitActivityJson,
                                    VisitLineJson:jsonLine
                                }
                                
                                this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                                this.$http.ejecutar("PUT", "VisitVoyage-update", VisitJson, { root: 'VisitJson' })
                                .then(response => {
                                    let res = [...response.data.data];
                                    this.$notify({
                                        group: 'container',
                                        text: res[0].Response,
                                        type: "success"
                                    });
                                    this.StatusFormulario = this.StatusFormulario === 1 ? 2 : this.StatusFormulario;
                                    this.$http.ejecutar('GET', 'VisitStatusMasterActivity-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusMasterActivity => {
                                        if(responseVisitStatusMasterActivity.status === 200){
                                            this.StatusMaster = responseVisitStatusMasterActivity.data.data;
                                            this.formTripVisity.tecleado = false;
                                            this.$http.ejecutar('GET', 'Visit-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusId => {
                                                if(responseVisitStatusId.status === 200){
                                                    let responseId = responseVisitStatusId.data.data[0];
                                                    this.itinerarySelected.VisitStatus = responseId.VisitStatus;   
                                                    this.itinerarySelected.VisitStatusEs = responseId.VisitStatusEs;
                                                    this.itinerarySelected.VisitStatusEn = responseId.VisitStatusEn;

                                                    this.itinerarySelected.LabelDeparture = responseId.LabelDeparture;
                                                    this.itinerarySelected.Departure = responseId.Departure;
                                                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                                    resolve(true);
                                                }else{
                                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                    resolve(false);
                                                }
                                            }).catch(err => {
                                                resolve(false);
                                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                this.$store.commit('visitas/messageMutation', err);
                                            });
                                        }else{
                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                            resolve(false);
                                        }
                                    }).catch(err => {
                                        resolve(false);
                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                        this.$store.commit('visitas/messageMutation', err);
                                    });
                                    /*this.resetsInput();
                                    this.$store.state.visitas.collapseTerminal = false;
                                    this.$emit('submited', res[0]);*/
                                }).catch((err) => {
                                    this.$store.commit('visitas/messageMutation', err);
                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                    resolve(false);
                                });
                            }else{
                                resolve(false);
                            } 

                        }else{

                                let jsonLine = [];
                                
                                let VisitJson = {
                                    VisitTerminalId: this.visitaTerminalId,
                                    VoyageArrival:this.formTripVisity.voyageArrival,
                                    VoyageDeparture:this.formTripVisity.voyageDeparture,
                                    ShippingLineAgencyId:this.formTripVisity.shippingAgentId,
                                    PortOperatorId:this.formTripVisity.portOperatorId,
                                    VisitActivityJson:VisitActivityJson,
                                    VisitLineJson:jsonLine
                                }
                                
                                this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                                this.$http.ejecutar("PUT", "VisitVoyage-update", VisitJson, { root: 'VisitJson' })
                                .then(response => {
                                    let res = [...response.data.data];
                                    this.$notify({
                                        group: 'container',
                                        text: res[0].Response,
                                        type: "success"
                                    });
                                    this.StatusFormulario = this.StatusFormulario === 1 ? 2 : this.StatusFormulario;
                                    this.$http.ejecutar('GET', 'VisitStatusMasterActivity-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusMasterActivity => {
                                        if(responseVisitStatusMasterActivity.status === 200){
                                            this.StatusMaster = responseVisitStatusMasterActivity.data.data;
                                            this.formTripVisity.tecleado = false;
                                            this.$http.ejecutar('GET', 'Visit-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusId => {
                                                if(responseVisitStatusId.status === 200){
                                                    let responseId = responseVisitStatusId.data.data[0];
                                                    this.itinerarySelected.VisitStatus = responseId.VisitStatus;   
                                                    this.itinerarySelected.VisitStatusEs = responseId.VisitStatusEs;
                                                    this.itinerarySelected.VisitStatusEn = responseId.VisitStatusEn;

                                                    this.itinerarySelected.LabelDeparture = responseId.LabelDeparture;
                                                    this.itinerarySelected.Departure = responseId.Departure;
                                                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                                    resolve(true);
                                                }else{
                                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                    resolve(false);
                                                }
                                            }).catch(err => {
                                                resolve(false);
                                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                                this.$store.commit('visitas/messageMutation', err);
                                            });
                                        }else{
                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                            resolve(false);
                                        }
                                    }).catch(err => {
                                        resolve(false);
                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                        this.$store.commit('visitas/messageMutation', err);
                                    });
                                    /*this.resetsInput();
                                    this.$store.state.visitas.collapseTerminal = false;
                                    this.$emit('submited', res[0]);*/
                                }).catch((err) => {
                                    this.$store.commit('visitas/messageMutation', err);
                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                    resolve(false);
                                });

                        }
                    }else{
                        resolve(true);
                    }
                    //this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                }
            }, 1000)
            
        })
    }
    async function registerDataScheduleArrival(){
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let arregloTotal = this.JsonVisitActivityShedule.ArriveJson;
                let messagesError = '';
                let VisitActivityJson = [];
                let bandera = false;
                arregloTotal.map(async (data) => {
                    if(messagesError === ''){
                        if(!data.FgDisabled){
                            if((data.Date === "" || data.Hour === "") && data.FgRequired){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if(((data.Date !== "" && data.Hour === "") || (data.Date === "" && data.Hour !== ""))){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if((data.Date === "" && data.Hour === "") && !data.FgRequired){
                                VisitActivityJson.push({
                                    VisitId : this.VisitId,
                                    VisitTerminalId: this.visitaTerminalId,
                                    VisitActivityId: data.VisitActivityId,
                                    VisitActivityVId: data.VisitActivityVId ? data.VisitActivityVId : "",
                                    Name: data.Name,
                                    Observation: data.Observation,
                                    ActivityDate: "",
                                    FgBerth: this.checkFgBerth(data) == '1' ? 1:0,
                                })
                                if(data.bandera){   
                                    bandera = true;
                                }
                            }else if(data.Date !== "" && data.Hour !== ""){
                                let isValid = moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm", true).isValid();
                                if(this.StatusFormulario < 2){
                                    if(data.bandera && !data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,arregloTotal) : messagesError;
                                            bandera = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }else if(data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,arregloTotal) : messagesError;
                                            bandera = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }
                                }else if(data.bandera){
                                    if(isValid){
                                        let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                        messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,arregloTotal) : messagesError;
                                        bandera = true;
                                    }else{
                                        messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                    }
                                }
                                if(isValid){
                                    let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                    VisitActivityJson.push({
                                        VisitId : this.VisitId,
                                        VisitTerminalId: this.visitaTerminalId,
                                        VisitActivityId: data.VisitActivityId,
                                        VisitActivityVId: data.VisitActivityVId ? data.VisitActivityVId : "",
                                        Name: data.Name,
                                        Observation: data.Observation,
                                        ActivityDate: moment(ActivityDate).format('YYYY-MM-DD HH:mm'),
                                        FgBerth: this.checkFgBerth(data) == '1' ? 1:0,
                                    })
                                }
                            }
                        }
                    }
                })
                if(messagesError !== ''){
                    this.$notify({
                        group: 'container',
                        type: 'error',
                        ignoreDuplicates:false,
                        title: 'ERROR',
                        text: messagesError
                    });
                    resolve(false);
                }else{
                    if(bandera){
                        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                        this.$http.ejecutar("PUT", "VisitSheduleActivity-insert", VisitActivityJson, { root: 'VisitActivityJson' })
                        .then(response => {
                            let res = [...response.data.data];
                            this.$notify({
                                group: 'container',
                                text: res[0].Response,
                                type: "success"
                            });
                            this.StatusFormulario = this.StatusFormulario === 1 ? 2 : this.StatusFormulario;
                            this.$http.ejecutar('GET', 'VisitStatusMasterActivity-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusMasterActivity => {
                                if(responseVisitStatusMasterActivity.status === 200){
                                    this.StatusMaster = responseVisitStatusMasterActivity.data.data;

                                    this.$http.ejecutar('GET', 'Visit-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusId => {
                                        if(responseVisitStatusId.status === 200){
                                            let responseId = responseVisitStatusId.data.data[0];
                                            this.itinerarySelected.VisitStatus = responseId.VisitStatus;   
                                            this.itinerarySelected.VisitStatusEs = responseId.VisitStatusEs;
                                            this.itinerarySelected.VisitStatusEn = responseId.VisitStatusEn;
                                            this.itinerarySelected.LabelDeparture = responseId.LabelDeparture;
                                            this.itinerarySelected.Departure = responseId.Departure;
                                            this.$store.state.visitas.apiStateForm = ENUM.INIT;
                                            resolve(true);
                                        }else{
                                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                            resolve(false);
                                        }
                                    }).catch(err => {
                                        resolve(false);
                                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                        this.$store.commit('visitas/messageMutation', err);
                                    });
                                }else{
                                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                    resolve(false);
                                }
                            }).catch(err => {
                                resolve(false);
                                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                                this.$store.commit('visitas/messageMutation', err);
                            });
                            
                            /*this.resetsInput();
                            this.$store.state.visitas.collapseTerminal = false;
                            this.$emit('submited', res[0]);*/
                        }).catch((err) => {
                            this.$store.commit('visitas/messageMutation', err);
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                            resolve(false);
                        });
                    }else{
                        resolve(true);
                    }
                }
            }, 1000)
        })
    }
    async function registerDataScheduleDeparture(){
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let arregloTotal = this.JsonVisitActivityShedule.DepartureJson;
                let messagesError = '';
                let VisitActivityJson = [];
                let bandera = false;
                arregloTotal.map(async (data) => {
                    if(messagesError === ''){
                        if(!data.FgDisabled){
                            if((data.Date === "" || data.Hour === "") && data.FgRequired){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if(((data.Date !== "" && data.Hour === "") || (data.Date === "" && data.Hour !== ""))){
                                messagesError = messagesError == '' ? (this.$t('label.dateRequired')+ this.titleItemsActivity(data)) : messagesError;
                            }else if((data.Date === "" && data.Hour === "") && !data.FgRequired){
                                VisitActivityJson.push({
                                    VisitId : this.VisitId,
                                    VisitTerminalId: this.visitaTerminalId,
                                    VisitActivityId: data.VisitActivityId,
                                    VisitActivityVId: data.VisitActivityVId ? data.VisitActivityVId : "",
                                    Name: data.Name,
                                    Observation: data.Observation,
                                    ActivityDate: "",
                                    FgBerth: this.checkFgBerth(data) == '1' ? 1:0,
                                })
                                if(data.bandera){   
                                    bandera = true;
                                }
                            }else if(data.Date !== "" && data.Hour !== ""){
                                let isValid = moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm", true).isValid();
                                if(this.StatusFormulario < 4){
                                    if(data.bandera && !data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,arregloTotal) : messagesError;
                                            bandera = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }else if(data.FgRequired){
                                        if(isValid){
                                            let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                            messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,arregloTotal) : messagesError;
                                            bandera = true;
                                        }else{
                                            messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                        }
                                    }
                                }else if(data.bandera){
                                    if(isValid){
                                        let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                        messagesError = messagesError == '' ? this.validationDate(ActivityDate,data,arregloTotal) : messagesError;
                                        bandera = true;
                                    }else{
                                        messagesError = messagesError == '' ? this.$t('label.dateInvalidIn')+this.titleItemsActivity(data) : messagesError;
                                    }
                                }
                                if(isValid){
                                    let ActivityDate = new Date(moment(data.Date + ' ' + data.Hour, "DD/MM/YYYY HH:mm").toDate());
                                    VisitActivityJson.push({
                                        VisitId : this.VisitId,
                                        VisitTerminalId: this.visitaTerminalId,
                                        VisitActivityId: data.VisitActivityId,
                                        VisitActivityVId: data.VisitActivityVId ? data.VisitActivityVId : "",
                                        Name: data.Name,
                                        Observation: data.Observation,
                                        ActivityDate: moment(ActivityDate).format('YYYY-MM-DD HH:mm'),
                                        FgBerth: this.checkFgBerth(data) == '1' ? 1:0,
                                    })
                                }
                            }
                        }
                    }
                })
                if(messagesError !== ''){
                    this.$notify({
                        group: 'container',
                        type: 'error',
                        ignoreDuplicates:false,
                        title: 'ERROR',
                        text: messagesError
                    });
                    resolve(false);
                }else{
                    if(bandera){
                        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                        this.$http.ejecutar("PUT", "VisitSheduleActivity-insert", VisitActivityJson, { root: 'VisitActivityJson' })
                        .then(response => {
                            let res = [...response.data.data];
                            this.$notify({
                                group: 'container',
                                text: res[0].Response,
                                type: "success"
                            });
                            resolve(true);
                            this.$store.state.visitas.apiStateForm = ENUM.INIT;
                            /*this.resetsInput();
                            this.$store.state.visitas.collapseTerminal = false;
                            this.$emit('submited', res[0]);*/
                        }).catch((err) => {
                            this.$store.commit('visitas/messageMutation', err);
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                            resolve(false);
                        });
                    }else{
                        resolve(true);
                    }
                }
            }, 1000)
        })
    }
    function portChange(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;
            const index = this.ListPort.map(function(e) {
                return e.PortId; 
            }).indexOf(id);
            if(index !== -1){
                this.ListBerth = this.ListPort[index].BerthJson.BerthJson;
            }
        }else{
            this.formTerminalVisity.BerthId = null;
            this.ListBerth = [];
        }
    }
    function onComplete(){
        this.resetsInput();
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'Visit-by-VisitId', { VisitId:this.VisitId }, '').then(responseVisitStatusId => {
            if(responseVisitStatusId.status === 200){
                let responseId = responseVisitStatusId.data.data[0];
                this.itinerarySelected.VisitStatus = responseId.VisitStatus;   
                this.itinerarySelected.VisitStatusEs = responseId.VisitStatusEs;
                this.itinerarySelected.VisitStatusEn = responseId.VisitStatusEn;

                this.itinerarySelected.LabelDeparture = responseId.LabelDeparture;
                this.itinerarySelected.Departure = responseId.Departure;
                this.$store.state.visitas.collapseTerminal = false;
                this.$emit('submited');
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', err);
        });
    }
    function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
    async function openModalUpdated(item,type){
        this.editActivity = true;
        this.itemSelectedDetails = item;
        this.typeActivity = type;
    }
    function cerrarModal(){
        this.editActivity = false;
    }
    function updatedData(){
        let indexOf = -1;
        if(this.typeActivity === 1){
            indexOf = this.JsonVisitActivityShedule.ArriveJson.map(function(e) {
                return e.VisitActivityId; 
            }).indexOf(this.itemSelectedDetails.VisitActivityId);
            if(indexOf !== -1){
                this.JsonVisitActivityShedule.ArriveJson[indexOf].Name = this.itemSelectedDetails.Name;
                this.JsonVisitActivityShedule.ArriveJson[indexOf].Observation = this.itemSelectedDetails.Observation;
                this.JsonVisitActivityShedule.ArriveJson[indexOf].bandera = true;
                this.editActivity = false;
            }else{
                this.editActivity = false;
                this.itemSelectedDetails = {
                    Name:"",
                    Observation:"",
                    Label: {En: "", Es: ""},
                    FgName: false,
                    FgObservation: false,
                    FgDisabled:false,
                };
            }
        }else if(this.typeActivity === 2){
            indexOf = this.JsonVisitActivityShedule.DepartureJson.map(function(e) {
                return e.VisitActivityId; 
            }).indexOf(this.itemSelectedDetails.VisitActivityId);
            if(indexOf !== -1){
                this.JsonVisitActivityShedule.DepartureJson[indexOf].Name = this.itemSelectedDetails.Name;
                this.JsonVisitActivityShedule.DepartureJson[indexOf].Observation = this.itemSelectedDetails.Observation;
                this.JsonVisitActivityShedule.DepartureJson[indexOf].bandera = true;
                this.editActivity = false;
            }else{
                this.editActivity = false;
                this.itemSelectedDetails = {
                    Name:"",
                    Observation:"",
                    Label: {En: "", Es: ""},
                    FgName: false,
                    FgObservation: false,
                    FgDisabled:false
                };
            }
        }
        
    }
    function validationsDates(fechaOriginal,condicional,tituloItem,idBusqueda,jsonBusqueda){
        let message = '';
        const index = jsonBusqueda.map(function(e) {
            return e.VisitActivityId; 
        }).indexOf(idBusqueda);
        if(index !== -1){
            let isValid = moment(jsonBusqueda[index].Date + ' ' + jsonBusqueda[index].Hour, "DD/MM/YYYY HH:mm", true).isValid();
            if(isValid){
                let fechaEspecial = new Date(moment(jsonBusqueda[index].Date + ' ' + jsonBusqueda[index].Hour, "DD/MM/YYYY HH:mm").toDate());
                let tituloEspecial = this.titleItemsActivity(jsonBusqueda[index]);
                switch (condicional.replace(/\s+/g, '')) {
                    case '>':
                        if(fechaEspecial.getTime() > fechaOriginal.getTime()){
                            message = this.$t('label.invalidDatesList.titleDate')+tituloItem+" "+this.$t('label.invalidDatesList.invalidlessThan')+tituloEspecial;
                        }
                        break;
                    case '<':
                        if(fechaEspecial.getTime() < fechaOriginal.getTime()){
                            message = this.$t('label.invalidDatesList.titleDate')+tituloItem+" "+this.$t('label.invalidDatesList.invalidgreaterThan')+tituloEspecial;
                        }
                        break;
                    case '>=':
                        if(fechaEspecial.getTime() >= fechaOriginal.getTime()){
                            message = this.$t('label.invalidDatesList.titleDate')+tituloItem+" "+this.$t('label.invalidDatesList.invalidgreaterThanOrEqual')+tituloEspecial;
                        }
                        break;
                    case '<=':
                        if(fechaEspecial.getTime() <= fechaOriginal.getTime()){
                            message = this.$t('label.invalidDatesList.titleDate')+tituloItem+" "+this.$t('label.invalidDatesList.invalidlessThanOrEqual')+tituloEspecial;
                        }
                        break;
                    case '=':
                        if(fechaEspecial.getTime() === fechaOriginal.getTime()){
                            message = this.$t('label.invalidDatesList.titleDate')+tituloItem+" "+this.$t('label.invalidDatesList.invalidOrEqual')+tituloEspecial;
                        }
                        break;
                    default:
                        message = ''
                        break;
                }
            }
        }
        return message;
    }
    function validarRequerido(item){
        return item.FgRequired ? "required " : "";
    }
    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }
    function setFgBerth(event, activity){
        if(this.activitiesLocations.length == 0) 
            this.activitiesLocations.push({ VisitActivityId: activity.VisitActivityId, FgBerth: event});

        let found = this.activitiesLocations.findIndex((item) => item.VisitActivityId == activity.VisitActivityId);
        if(found == -1)
            this.activitiesLocations.push({ VisitActivityId: activity.VisitActivityId, FgBerth: event});
        else
            this.activitiesLocations[found].FgBerth = event;

        activity.bandera = true;
    }
    function checkFgBerth(activity) {
        if(!activity.FgInspection) return '0';

        let found = this.activitiesLocations.findIndex((item) => item.VisitActivityId == activity.VisitActivityId);

        if(activity.FgBerth && found == -1)
            return activity.FgBerth == 1 ? '1':'0';
        
        if(found != -1)
            return `${this.activitiesLocations[found].FgBerth}`;
        else 
            return '0';
    }

    //computed
    function BerthBayOptions() {
        return [
            { value: '1', label: this.$t('label.berth'), props: { addLabelClasses: 'col-form-label-sm p-0' }},
            { value: '0', label: this.$t('label.bayInspection'), props: { addLabelClasses: 'col-form-label-sm p-0' }},
        ];
    }
    function realToken(){
        if(this.visitaTerminalId === 0){
            return this.token;
        }else{
            return this.itemSelected.Called !== "" && this.itemSelected.Called !== null ? this.itemSelected.Called : this.token;
        }
    }
    function LineDock(){
        if(this.formTerminalVisity.BerthId !== null){
            const index = this.ListBerth.map(function(e) {
                return e.BerthId; 
            }).indexOf(this.formTerminalVisity.BerthId);
            if(index !== -1){
                return this.ListBerth[index].LineDock;
            }else{
                return 0;
            }
        }else{
            return 0;
        }
    }
    function Draft(){
        if(this.formTerminalVisity.BerthId !== null){
            const index = this.ListBerth.map(function(e) {
                return e.BerthId; 
            }).indexOf(this.formTerminalVisity.BerthId);
            if(index !== -1){
                return this.ListBerth[index].Draft;
            }else{
                return 0;
            }
        }else{
            return 0;
        }
    }
    function optionsListLevelProtectionAll() {
        if (this.ListLevelProtection.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListLevelProtection.map(function(e) {
                chart.push({
                    value: e.ProtectionLevelId,
                    label: e.ProtectionLevelName,
                });
            });
            return chart;
        }
    }
    function optionsListServicePortAll() {
        if (this.ListServicePort.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListServicePort.map(function(e) {
                chart.push({
                    value: e.PortId,
                    label: e.PortName,
                });
            });
            return chart;
        }
    }
    function optionsListPortAll() {
        if (this.ListPort.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListPort.map(function(e) {
                chart.push({
                    value: e.PortId,
                    label: e.PortName,
                });
            });
            return chart;
        }
    }
    function optionsListTerminalAll() {
        if (this.ListTerminal.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListTerminal.map(function(e) {
                chart.push({
                    value: e.TerminalConfigId,
                    label: e.TerminalName,
                });
            });
            return chart;
        }
    }
    function optionsListTpOperationAll() {
        if (this.ListTpOperation.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListTpOperation.map(function(e) {
                chart.push({
                    value: e.OperationId,
                    label: e.OperationName,
                });
            });
            return chart;
        }
    }
    function optionsListTpConditionPlanningAll() {
        if (this.ListTpConditionPlanning.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListTpConditionPlanning.map(function(e) {
                chart.push({
                    value: e.ConditionPlanningId,
                    label: e.ConditionPlanningName,
                });
            });
            return chart;
        }
    }
    function optionsListBerthAll() {
        if (this.ListBerth.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListBerth.map(function(e) {
                chart.push({
                    value: e.BerthId,
                    label: e.BerthName,
                });
            });
            return chart;
        }
    }
    //tab2
    function vesselName(){
        if(this.collapseTerminal){
            return this.itinerarySelected.VesselName;
        }else{
            return '';
        }
    }
    function vesselRouter(){
        if(this.collapseTerminal){
            return `${this.$store.getters["connection/getBase"]}${this.itinerarySelected.VesselFlagRoute}`;
        }else{
            return '/img/errorimage.jpg';
        }
    }
    function fields(){ 
        return [
            { key: 'RowNumber', label: '#',filter :false },
            { key: 'ArrivalDescription', label:  this.$t('label.berthVisit') ,_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'DepartureDescription', label: this.$t('label.departure'),_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell"},
            { key: 'FormatedDate', label: this.$t('label.FECHA'),_classes:"center-cell", _style:'min-width:150px;'},
        ]
    }
    function columnDefs(){ 
        return [ 
            {
                headerName: this.$t('label.carrier'),
                field: 'ShippingLineName',
                editable: false,
                width: 300,
            },
            {
                headerName: this.$t('label.voyage'),
                field: 'AliasShippingLineVoyage',
                headerTooltip: this.$t('label.doubleClickEdit'),
                headerComponentParams: {
                    menuIcon: 'fa-bars',
                    template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
                        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
                        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
                        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>&nbsp;<i class="fa fa-pencil-alt"></i>' +
                        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                        '  </div>' +
                        '</div>'
                },
                editable: true,
                width: 300,
            },
        ]
    }
    function optionsListClienteAll(opcion) {
        if (this.ListAgenteNaviero.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            if(opcion == 1){
                this.ListAgenteNaviero.map(function(e) {
                    chart.push({
                        value: e.ClientTpId,
                        label: e.ClientName,
                    });
                });
            }else{
                this.ListOperadorPortuario.map(function(e) {
                    chart.push({
                        value: e.ClientTpId,
                        label: e.ClientName,
                    });
                });
            }
            
            return chart;
        }
    }
    function formatedItemsHistory() {
        return this.ListServiceItineraryHist.map((item,index) => Object.assign({}, item, {
            RowNumber: index+1,
            ArrivalDescription: item.ArrivalDs+': '+DateFormater.formatDateTimeWithSlash(item.Arrival),
            DepartureDescription: item.DepartureDs+': '+DateFormater.formatDateTimeWithSlash(item.Departure),
            UltimoUsuario: item.TransaLogin,
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A'
        }));
    }
    //tab3
    function voyageName(){
        if(this.collapseTerminal){
            return this.itinerarySelected.Voyage;
        }else{
            return '';
        }
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    export default {
        name:'register-terminal',
        props:['token','itemSelected','visitaTerminalId','apiStateLoading'],
        mixins: [AgGrid],
        directives: {
            money: VMoney,
        },
        directives: UpperCase,
        data,
        components: {
            Money,
        },
        validations(){ return FormVisity() },
        methods:{
            cerrarModal,
            portChange,
            handleTabChange,
            registerDataTerminal,
            mountedMont,
            resetsInput,
            titleItemsActivity,
            rangeDays,
            onComplete,
            setLoading,
            formatHourMMHH,
            SoloNumeros,
            validacionFechas,
            optionsListClienteAll,
            onGridReady,
            registerDataVoyage,
            openModalUpdated,
            updatedData,
            validationDate,
            validationsDates,
            registerDataScheduleArrival,
            registerDataScheduleDeparture,
            validarRequerido,
            setFgBerth,
            checkFgBerth,
            cerrarTodo(){
                this.$emit('submited');
                this.$store.state.visitas.collapseTerminal = false;
            },
            validateDateRange,
        },
        beforeDestroy(){
            this.resetsInput();
        },
        computed:{
            realToken,
            LineDock,
            Draft,
            formatedItemsHistory,
            optionsListLevelProtectionAll,
            optionsListPortAll,
            optionsListTerminalAll,
            optionsListTpOperationAll,
            optionsListTpConditionPlanningAll,
            optionsListBerthAll,
            optionsListServicePortAll,
            vesselName,
            vesselRouter,
            fields,
            columnDefs,
            tableTextHelpers,
            voyageName,
            BerthBayOptions,
            ...mapState({
                ItineraryId: state => state.visitas.ItineraryId,
                StowagePlanningId: state => state.visitas.StowagePlanningId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                collapseTerminal: state => state.visitas.collapseTerminal,
                VisitId: state => state.visitas.VisitId,
                bulkCarrier: state => state.visitas.bulkCarrier,
            })
        },
        watch:{
            collapseTerminal: async function(newValue) {
                if(newValue){
                    this.mountedMont();
                    this.Ata = new Date(moment((this.itinerarySelected.Arrival), "DD/MM/YYYY HH:mm").toDate());
                    this.Atd = new Date(moment((this.itinerarySelected.Departure), "DD/MM/YYYY HH:mm").toDate());
                    let currentDate = new Date();
                    this.laterDate = currentDate;
                    this.previousDate =  new Date(currentDate.getFullYear()-10,1);

                }else{

                    this.resetsInput();
                    this.$refs.wizard.reset();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .mx-datepicker{
        width:100%
    }
    /* This is a css loader. It's not related to vue-form-wizard */
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    .loader {
        margin: 60px auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #e74c3c;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
        }
        @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .cajita-texto{
        margin:5px;
        padding:5px;
        margin-bottom:20px;
        text-align:center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
        border-radius: 5px;
    }

    .fixed-tabs-height {
        height: 100% !important;
    }
    .fixed-tab-height {
        height: 81% !important;
    }
</style>
